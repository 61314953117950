import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "./SingleDocument.scss";
import { ReactComponent as Download } from "icons/file-arrow-down-bold.svg";
import IntroSection from "components/intro-section/IntroSection";
import { renderAttribute, filterAttribute } from "functions/renderAttribute";
import AttachmentButton from "components/buttons/attachmentButton";
import { AttachmentDifferentFormatButton } from "components/buttons/attachmentButton";
import PrivateAttachmentButton from "components/buttons/privateAttachmentButton";
import Placeholders from "components/placeholders/Placeholders";

const format = (title) => {
  let arr = [];
  let exludeWords = ["e", "di"];
  arr = title.split("-");
  return arr
    .map((word, i) => {
      return exludeWords.includes(word) && i !== 0
        ? [word]
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export default class SingleDocument extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      let document = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/Document",
        {
          documentId: id,
        }
      );
      let file = false;
      if( document?.data?.data?.document?.filePointer && document?.data?.data?.document?.filePointer?.file?.name ) {
        file = document?.data?.data?.document?.filePointer;
      }
      document = document?.data?.data?.document?.values;
      this.setState({ document, file });
    } catch (err) {
      console.log("Error CDM SingleDocument");
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div id="single-document">
        {this.props.match.url.split("/")[1] !== "pubblicazioni" && (
          <IntroSection
            category={format(this.props.match.url.split("/")[1])}
            subsector={format(this.props.match.url.split("/")[2])}
            subsubsector={format(this.props.match.url.split("/")[3])}
          />
        )}
        <div className="container pad-mobile">
          {this.state.loading ? (
            <Placeholders number={1} type="new" />
          ) : (
            <>
              {renderAttribute(this.state.document, "AZIENDA") ? (
                <span className="general">
                  {" "}
                  {renderAttribute(this.state.document, "AZIENDA")} -{" "}
                  {renderAttribute(this.state.document, "CITTA")}
                </span>
              ) : !renderAttribute(this.state.document, "NUMEROATTO") ? (
                renderAttribute(this.state.document, "NUMERO") ? (
                  <>
                    {renderAttribute(this.state.document, "FEDERAZIONE") && (
                      <span className="general">
                        {" "}
                        {renderAttribute(this.state.document, "FEDERAZIONE")}
                      </span>
                    )}
                    <span className="date">
                      {"Nota n." +
                        renderAttribute(this.state.document, "NUMERO") +
                        " del " +
                        moment(
                          renderAttribute(this.state.document, "DATA")
                        ).format("L")}
                    </span>
                  </>
                ) : (
                  <span className="date">
                    {renderAttribute(this.state.document, "DATA") &&
                      moment(
                        renderAttribute(this.state.document, "DATA")
                      ).format("L")}
                  </span>
                )
              ) : (
                <span className="general">
                  {renderAttribute(this.state.document, "NUMEROATTO")}
                </span>
              )}{" "}
              <h2 style={{ marginBottom: "1rem" }}>
                {renderAttribute(this.state.document, "NUMERO_FOCUS") &&
                  renderAttribute(this.state.document, "NUMERO_FOCUS") + " - "}
                {renderAttribute(this.state.document, "TITOLO")}
                {renderAttribute(this.state.document, "NOMEATTO")}
              </h2>
              {renderAttribute(this.state.document, "A CURA") && (
                <div style={{ paddingBottom: 16 }}>
                  <p style={{ fontWeight: "bold", paddingBottom: 4 }}>
                    A CURA DI
                  </p>
                  <p>{renderAttribute(this.state.document, "A CURA")}</p>
                </div>
              )}
              {renderAttribute(this.state.document, "THTML") && (
                <p
                  className="styledText"
                  dangerouslySetInnerHTML={{
                    __html: renderAttribute(this.state.document, "THTML"),
                  }}
                />
              )}
              {renderAttribute(this.state.document, "TESTO") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(this.state.document, "TESTO"),
                    }}
                  />
                </div>
              )}
              {renderAttribute(this.state.document, " TESTO") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(this.state.document, " TESTO"),
                    }}
                  />
                </div>
              )}
              {(this.props.match.url.split("/")[1] !== "pubblicazioni" &&
                renderAttribute(this.state.document, "PRESENTAZIONE")) ||
              renderAttribute(this.state.document, "THTML") ||
              renderAttribute(this.state.document, "TESTO") ? (
                <div>
                  {renderAttribute(this.state.document, "PRESENTAZIONE") && (
                    <h3 style={{ paddingBottom: 8 }}>Presentazione</h3>
                  )}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(
                        this.state.document,
                        "PRESENTAZIONE"
                      ),
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(this.state.document, "ABSTRACT"),
                    }}
                  />
                </div>
              )}
              {renderAttribute(this.state.document, "DESCRIZIONE") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(
                        this.state.document,
                        "DESCRIZIONE"
                      ),
                    }}
                  />
                </div>
              )}
              {renderAttribute(this.state.document, "OGGETTO") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(this.state.document, "OGGETTO"),
                    }}
                  />
                </div>
              )}
              {renderAttribute(this.state.document, "NOTE") && (
                <div className="styledText">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(this.state.document, "NOTE"),
                    }}
                  />
                </div>
              )}
            </>
          )}
          <div className="allegati-list">
            <RenderAllegato type="ALLEGATOPUB" document={this.state.document} />
            <RenderAllegato
              type="ALLEGATO"
              private={true}
              document={this.state.document}
            />
            <RenderAllegato
              type="ALLEGATOPRI"
              private={true}
              document={this.state.document}
            />
            <RenderAllegato type="DOCUMENTO" document={this.state.document} />
            {this.state.document &&
              filterAttribute(this.state.document, "PRESENTAZIONE") && (
                <RenderAllegato
                  type="PRESENTAZIONE"
                  document={this.state.document}
                />
              )}
            <RenderAllegato
              type="ALLEGATO_PUBBLICO"
              document={this.state.document}
            />
            <RenderAllegato type="ALLEGATOWEB" document={this.state.document} />
            <RenderAllegato
              type="ALLEGATOINTERNO"
              document={this.state.document}
            />
            {this.state.document &&
              filterAttribute(this.state.document, "LINKESTERNO") &&
              filterAttribute(this.state.document, "LINKESTERNO").map(
                (link) => (
                  <a
                    key={`link_${link.value.id}`}
                    href={link.value.url}
                    className="btn"
                    style={{ marginLeft: 8 }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Download
                      width={16}
                      height={16}
                      style={{ marginRight: 4 }}
                    />
                    {link.value.description
                      ? link.value.description
                      : link.value.file && link.value.file.name}
                  </a>
                )
              )}
            {this.state.document &&
              filterAttribute(this.state.document, "LINK") &&
              filterAttribute(this.state.document, "LINK").map((link) => (
                <a
                  key={`link_${link.value.url}`}
                  href={link.value.url}
                  className="btn"
                  style={{ marginLeft: 8 }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Download width={16} height={16} style={{ marginRight: 4 }} />
                  {link.value.description ? link.value.description : "Link"}
                </a>
              ))}
            <div
              className="pdf-list"
              style={{ flexWrap: "wrap", marginBottom: 16 }}
            >
              {!this.state.loading &&
                this.state.document
                  ?.filter((value) => value.code === "ALLDOC")
                  ?.map((document) => (
                    <AttachmentDifferentFormatButton
                      text={document.value.file.name}
                      id={document.value.filePointer.id}
                    />
                  ))}
                {!this.state.loading &&
                this.state.file && <AttachmentButton
                      text={this.state.file.file.name}
                      id={this.state.file.id}
                    />
                }
            </div>
            <div
              className="pdf-list"
              style={{ flexWrap: "wrap", marginBottom: 16 }}
            >
              {!this.state.loading &&
                this.state.document
                  ?.filter((value) => value.code === "ALLDOCPRIV")
                  ?.map((document) => (
                    <AttachmentDifferentFormatButton
                      text={document.value.file.name}
                      id={document.value.filePointer.id}
                      history={this.props.history}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class RenderAllegato extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        {" "}
        {this.props.document && (
          <>
            {!this.props.private
              ? filterAttribute(this.props.document, this.props.type) &&
                filterAttribute(this.props.document, this.props.type)
                  .filter(
                    (allegato) =>
                      allegato &&
                      allegato.value &&
                      (allegato.value.description || allegato.value.filePointer.file)
                  )
                  .map((allegato, i) => {
                    //const id = this.props.type.indexOf('ALLEGATO') !== -1 ? allegato.value.filePointer.id : allegato.value.id;
                    const id = typeof allegato.value.filePointer.id !== 'undefined' && allegato.value.filePointer.id !== null ? allegato.value.filePointer.id : allegato.value.id;
                    return (
                      <div
                        key={i}
                        style={{
                          paddingTop: 0,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <AttachmentButton
                          id={id}
                          text={
                            allegato.value.description
                              ? allegato &&
                                allegato.value &&
                                allegato.value.description &&
                                allegato.value.description
                                  .slice(0, 30)
                                  .concat("...")
                              : allegato &&
                                allegato.value &&
                                allegato.value.filePointer.file &&
                                allegato.value.filePointer.file.name &&
                                allegato.value.filePointer.file.name
                                  .slice(0, 30)
                                  .concat("...")
                          }
                        />
                      </div>
                    );
                  })
              : filterAttribute(this.props.document, this.props.type) &&
                filterAttribute(this.props.document, this.props.type).map(
                  (allegato) => {
                    const id = this.props.type.indexOf('ALLEGATO') !== -1 ? allegato.value.filePointer.id : allegato.value.id;
                    return (
                      <div
                        style={{
                          paddingTop: 0,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <PrivateAttachmentButton
                          id={id}
                          text={
                            allegato.value.description
                              ? allegato.value.description
                              : allegato.value.file.filePointer && allegato.value.file.filePointer.name
                          }
                        />
                      </div>
                    );
                  }
                )}
          </>
        )}
      </div>
    );
  }
}
