import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import DocumentiList from "components/documents-list/DocumentiList";
import Filter from "components/filter/Filter";
import Spinner from "components/spinner/Spinner";
import IntroSection from "components/intro-section/IntroSection";
import { switchDocumenti } from "./functions";
import Pagination from "components/pagination/Pagination";


export default class DocumentiSection extends Component {
  state = {
    documenti: [],
    loading: true,
    active: {
      folder: null,
    },
    folders: [],
    totalPages: 1,
  };

  async componentDidMount() {
    try {
      const bakFilter = sessionStorage.getItem("documenti_section_bak");
      const sector = switchDocumenti();
      const foldersDataFilters = await this.fetchAnni(sector);
      const foldersFilter = foldersDataFilters?.data?.data;
      this.setState({
        active: {
            folder: ''
        }, 
        folders: foldersFilter?.folders,
      });
    } catch (err) {
      console.log("Err CDM DocumentiSezioni", err);
    }
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.active.folder !== oldState.active.folder
    ) {
      this.setState({ loading: true });
      sessionStorage.setItem(
        "documenti_section_bak",
        JSON.stringify(this.state.active)
      );
      try {
        const resDocumenti = await this.fetchDocumenti(switchDocumenti(), 0);
        const documenti = resDocumenti?.data?.data;
        this.setState({
          documenti: documenti?.documents,
          totalPages: documenti?.totalPages,
        });
      } catch (err) {
        console.log("Err CDU DocumentiSezioni", err);
      }
      this.setState({ loading: false });
    }
  }

  fetchAnni = async (sector) => {
    return await axios.post(
      "https://api.utilitalia.it/DMS/Folders/List",
      {
        path: sector.path,
        orderby: "folder",
      }
    );
  };

  fetchDocumenti = async (sector, page) => {
    let folder = '';
    if( this.state.active.folder !== null && this.state.active.folder !== '' && this.state.folders.length > 0) {
        folder = this.state.folders.filter( f => f.name === this.state.active.folder )[0].code
    }
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        "documentClasses": [
            "CONTENUTO_WEB"
        ],
        "heading": sector.path,
        folder,
        "documentValueDefs": [
                    {
                "code" : "TITOLO"
            },
            {
                "code" : "DATA"
            },
            {
                "code" : "SETTORE"
            }
        ],
        "pageSize" : "0",
        "pageStart" : page,
        "documentValueOrders":[
            {
            "code": "DATA",
            "type": "1",
            "direction" : "desc"
            }
        ]
        
      }
    );
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleReset = () => {
    this.setState({ active: { year: moment().format("YYYY") } });
  };
  handlePageClick = async (data) => {
    this.setState({ loading: true });
    try {
      window.scrollTo(0, 0);
      let { selected } = data;
      const response = await this.fetchDocumenti(
        switchDocumenti(),
        (selected || 0) * 20
      );
      this.setState({
        circolari: response?.data?.data?.circolari,
      });
    } catch (err) {
      console.log("Error fetchDocumenti handlePageClick DocumentiSezioni", err);
    }
    this.setState({ loading: false });
  };

  render() {
    const filters = [
      {
        name: "folder",
        text: "Seleziona l'area",
        values: this.state?.folders ? this.state?.folders.map( folder => folder?.name ) : [],
      },
    ];
    return (
      <div id="circolari">
        <IntroSection category={switchDocumenti().name} subsector="Documenti" />
        <div className="container pad-mobile">
          <h2 className="general">Documenti e posizionamenti</h2>
          <Filter
            filters={filters}
            change={this.handleChange}
            submit={this.handleSubmit}
            value={this.state.active}
            reset={this.handleReset}
            resetButton={this.state.active.folder !== ''}
          />
          {this.state.loading ? (
            <Spinner />
          ) : (
            <DocumentiList documents={this.state.documenti} col={4} />
          )}
          {this.state.totalPages > 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: 32,
              }}
            >
              <Pagination
                totalPages={this.state.totalPages}
                handlePageClick={this.handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
