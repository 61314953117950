import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./Documento.scss";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import moment from "moment";
import AttachmentButton from "components/buttons/attachmentButton";
import Placeholders from "components/placeholders/Placeholders";
function sanitizeMetaDescription(html) {
    // Creiamo un elemento temporaneo per rimuovere i tag HTML
    let tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Otteniamo solo il testo pulito
    let text = tempElement.textContent || tempElement.innerText || "";

    // Rimuoviamo spazi extra e decodifichiamo le entità HTML
    text = text.replace(/\s+/g, " ").trim();

    // Limitiamo a 160 caratteri per una meta description valida
    if (text.length > 160) {
        text = text.substring(0, 157) + "...";
    }

    return text;
}


export default function Documento(props) {
  const [loading, setLoading] = useState(true);
  const [documento, setDocumento] = useState({});
  useEffect(() => {
    fetchDocumento();
  }, [props.match.params.id]);

  const fetchDocumento = async () => {
    setLoading(true);
    try {
      const documentId = props.match.params.id;
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/Document",
        {
          documentId,
          "documentValueDefs": [
        {
            "code":"TITOLO"
        },
        {
            "code":"DATA"
        },
        {
            "code":"TESTO"
        },
        {
            "code":"ALLEGATO_PRINCIPALE"
        },
        {
            "code":"ALLEGATO"
        }
    ]
        }
      );
      const data =  response.data && response.data.data ? {
        data: response.data.data.document.values.filter( v => v.code === 'DATA').length > 0 ? response.data.data.document.values.filter( v => v.code === 'DATA')[0].value : null,
        titolo: response.data.data.document.values.filter( v => v.code === 'TITOLO').length > 0 ? response.data.data.document.values.filter( v => v.code === 'TITOLO')[0].value : null,
        testo: response.data.data.document.values.filter( v => v.code === 'TESTO').length > 0 ? response.data.data.document.values.filter( v => v.code === 'TESTO')[0].value : null,
        allegato: response.data.data.document.values.filter( v => v.code === 'ALLEGATO_PRINCIPALE').length > 0 ? response.data.data.document.values.filter( v => v.code === 'ALLEGATO_PRINCIPALE')[0].value : null
      } : {}
      console.log( data );
      setDocumento(
        data
      );
    } catch (err) {
      console.log("Error fetching documento CDM Documento", err);
    }
    setLoading(false);
  };

  return (
    <div id="documento">
      <Helmet>
        <title>
          {`Utilitalia - Documento |
            ${documento && documento.titolo}`}
        </title>
        <meta name="description" content={sanitizeMetaDescription(documento && documento.testo)} />
      </Helmet>
      <div className="container pad-mobile heading">
        <div className="back" onClick={props.history.goBack}>
          <NavigationCircleLeft />
          <span className="general">Torna INDIETRO</span>
        </div>
        <div className="center">SCHEDA DOCUMENTO</div>
        <div className="right"></div>
      </div>
      <div className="bg-white">
        <div
          className="container pad-mobile content"
          style={{ maxWidth: "970px" }}
        >
          {loading ? (
            <Placeholders number={1} type="new" />
          ) : (
            <>
              {/*<div>
                {moment(documento && documento.data).format("DD/MM/YYYY")}
              </div>*/}
              { documento.titolo && <h2 dangerouslySetInnerHTML={{__html:documento && documento.titolo}}/> }
              { documento.testo && <p
                dangerouslySetInnerHTML={{
                  __html: documento && documento.testo,
                }}
              /> }
              { documento?.allegato && <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                {/* <div
                  onClick={() =>
                    fetchPdf(documento.documentoFilePointer.id)
                  }
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  className="btn"
                >
                  <Download width={16} height={16} style={{ marginRight: 4 }} />
                  {documento.documentoFilePointer.file.name}
                </div> */}
                <AttachmentButton
                  text={
                    documento &&
                    documento.allegato &&
                    documento.allegato.filePointer &&
                    documento.allegato.filePointer.file.name
                  }
                  id={
                    documento &&
                    documento.allegato &&
                    documento.allegato.filePointer.id
                  }
                />
                
              </div> }
            </>
          )}
        </div>
      </div>
    </div>
  );
}
