/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./CategoryPage.scss";
import AreaListNew from "components/area-list/AreaListNew2";
import { BiPlus as Plus } from "react-icons/bi";
import NavigationCircleRight from "components/navigation/circle/NavigationCircleRight";
import { IconContext } from "phosphor-react";
import { formatSubCategory } from "functions/formatAddress";
import { renderCategory } from "./renderCategory";
import { renderIcon } from "./renderIcon";
import { isMobile } from "react-device-detect";
import SocialList from "components/social-list/SocialList";

const initialState = {
  features: [],
  boxes: [],
  activeDescription: null
};

const Box = ({element, i, state})=> (
    <>
    { element.socialList && <div className="box box--black" css={css`
              background: white;
            `}>{" "}
            {renderIcon(element.icon, state.color)}
            <div>
              <h3
                dangerouslySetInnerHTML={{
                  __html: element.title,
                }}
              /><SocialList /></div></div> }
    { element.empty && <hr class="hr-box" />}
    { element.onlyTitle && !element.empty && !element.socialList && <h4 class="title-box" dangerouslySetInnerHTML={{__html:element.title}} /> }
    { !element.onlyTitle && !element.empty && !element.socialList && <>{element.name === "youtube" ? (
          <a
            className="box"
            //href="https://www.youtube.com/user/FederutilityStampa"
            href="https://www.youtube.com/@YoutubeUtilitalia/featured"
            target="_blank"
            rel="noreferrer"
            key={i}
            css={css`
              background: white;
              &:hover {
                background: var(--${state.color});
              }
            `}
          >
            {" "}
            {renderIcon(element.icon, state.color)}
            <div>
              <h3
                dangerouslySetInnerHTML={{
                  __html: element.title,
                }}
              />
              {element.icon === "envelope" ? (
                <>
                  <p>
                    Email:{" "}
                    <span
                      style={{ color: `var(--${state.category})` }}
                    >
                      {element.email}
                    </span>
                  </p>
                  {element.tel && <p>Tel: {element.tel}</p>}
                  {element.fax && <p>Fax: {element.fax}</p>}
                </>
              ) : (
                <p dangerouslySetInnerHTML={{__html:element.text}}></p>
              )}
            </div>
            <NavigationCircleRight />
          </a>
        ) : (<>{ element?.to && element.to.indexOf('https://') !== -1 ? (
                <a
                target="_blank"
              href={element.to}
              key={i}
              className="box"
              css={css`
                background: white;
                &:hover {
                  background: var(--${state.color});
                }
              `}
            >
              {renderIcon(element.icon, state.color)}
              <div>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: element.title,
                  }}
                />
                {element.icon === "envelope" ? (
                  <>
                    <p>
                      Email:{" "}
                      <span
                        style={{ color: `var(--${state.category})` }}
                      >
                        {element.email}
                      </span>
                    </p>
                    {element.tel && <p>Tel: {element.tel}</p>}
                    {element.fax && <p>Fax: {element.fax}</p>}
                  </>
                ) : (
                  <p dangerouslySetInnerHTML={{__html:element.text}}></p>
                )}
              </div>
              <NavigationCircleRight />
            </a>) : 
          (<Link
              to={element.to}
              key={i}
              className="box"
              css={css`
                background: white;
                &:hover {
                  background: var(--${state.color});
                }
              `}
            >
              {renderIcon(element.icon, state.color)}
              <div>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: element.title,
                  }}
                />
                {element.icon === "envelope" && !element.isContact ? (
                  <>
                    <p>
                      Email:{" "}
                      <span
                        style={{ color: `var(--${state.category})` }}
                      >
                        {element.email}
                      </span>
                    </p>
                    {element.tel && <p>Tel: {element.tel}</p>}
                    {element.fax && <p>Fax: {element.fax}</p>}
                  </>
                ) : (
                  <p dangerouslySetInnerHTML={{__html:element.text}}></p>
                )}
              </div>
              <NavigationCircleRight />
            </Link>)
        }
        </>)}</>}
</>)

export default class CategoryPage extends Component {
  state = initialState;

  async componentDidMount() {
    const category = renderCategory(this.props.location.pathname);
    this.setState({ ...category });
  }

  componentDidUpdate(oldProps, oldState) {
    if (this.props.location.pathname !== oldProps.location.pathname) {
      this.setState(initialState);
      this.setState(renderCategory(this.props.location.pathname));
    }
  }

  handleClick(feature) {
    this.setState({ activeDescription: feature });
  }
  handleClickMore(feature) {
    this.setState({ activeDescription: feature });
  }

  render() {
    const hasSubcategory =
      !!this.state.subcategory && typeof this.state.subcategory !== "undefined";
      const checkMoreActive = (i)=> {
        if( this.state.features) {         
            return this.state.activeDescription === (i + this.state.features.length ) ;
        } else {
             return this.state.activeDescription === i;
        }
      }
    return (
      <div id="category-page">
        {this.state.title && (
          <Helmet>
            <title>{this.state.title}</title>
            <meta name="description" content={this.state.description} />
          </Helmet>
        )}
        <div
          className={hasSubcategory ? "hero2" : "hero"}
          style={{ backgroundImage: `url(${this.state.image})` }}
        >
          {/* <div id="motto" key="motto" className="description">
            {this.state.motto && (
              <div>
                <h1>
                  "Conoscere il tuo pianeta
                  <br /> è un passo verso il proteggerlo"
                </h1>
                <div className="cit">Jacques-Yves Cousteau</div>
              </div>
            )}
          </div> */}
          <div
            id="area-sector-title"
            key="area-sector-title"
            className="overlay pad-mobile"
            style={{
              background: `var(--${
                this.state.gradient ? this.state.gradient : this.state.color
              })`,
            }}
          >
            <div className="row">
              <div className="sector">
                {this.state.sector ? "Settore" : "Area"}
              </div>
              <div className="right">
                {this.state.icon}
                <span
                  style={{ fontWeight: hasSubcategory ? "normal" : "bold" }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.name,
                  }}
                />
                {hasSubcategory && (
                  <>
                    <span style={{ fontSize: 48, opacity: 0.5 }}>·</span>
                    <span
                      style={{ fontWeight: "bold" }}
                      dangerouslySetInnerHTML={{
                        __html: `${
                          this.props.match.params &&
                          this.props.match.params.subsector &&
                          formatSubsector(this.props.match.params.subsector)
                        }`,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {!this.state.disableWhatWeDo && (
          <div className="cosa-facciamo container pad-mobile">
            <h6>Cosa Facciamo</h6>
            <h2
              className="general"
              dangerouslySetInnerHTML={{
                __html: `Di cosa si occupa
              <span>
              ${this.state.name && this.state.name}
              ${
                this.state.subcategory
                  ? ` ·  ${formatSubCategory(this.state.subcategory)}`
                  : ""
              }
              </span>`,
              }}
            />
          </div>
        )}
        <div className="features">
          {window.innerWidth > 768
            ? this.state.features.map((element, i) => (
                <div
                  key={i}
                  style={{
                    background:
                      this.state.activeDescription === i &&
                      `var(--${this.state.color})`,
                  }}
                  className={
                    this.state.activeDescription === i
                      ? "feature active"
                      : "feature"
                  }
                >
                  <div className="number">{i + 1}</div>
                  {this.state.activeDescription === i ? (
                    <p className="text" dangerouslySetInnerHTML={{__html:element.text}}></p>
                  ) : (
                    <div className="preview">
                      <div
                        onClick={() => this.handleClick(i)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Plus
                          css={css`
                            color: var(--${this.state.color});
                            border-color: var(--greyColor);
                            &:hover {
                              color: white;
                              background: var(--${this.state.color});
                              border-color: var(--${this.state.color});
                            }
                          `}
                        />
                        <div style={{ paddingTop: 8, cursor: 'pointer' }}>Leggi di più</div>
                      </div>
                      <h3 dangerouslySetInnerHTML={{__html:element.title}} />
                    </div>
                  )}
                </div>
              ))
            : this.state.features.map((element, i) => (
                <div
                  style={{
                    background: `var(--${this.state.color}`,
                    color: "#fff",
                  }}
                  className="feature"
                  key={i}
                >
                  <div className="preview">
                    <h3 style={{ paddingBottom: "1rem" }}>{element.title}</h3>
                    <p className="text" dangerouslySetInnerHTML={{__html:element.text}}></p>
                  </div>
                </div>
              ))}
        </div>
        { this.state?.moreFeatures && <div className="features features--more">
          {window.innerWidth > 768
            ? this.state.moreFeatures.map((element, i) => (
                <div
                  key={i}
                  style={{
                    background:
                      checkMoreActive(i) &&
                      `var(--${this.state.color})`,
                  }}
                  className={
                    checkMoreActive(i)
                      ? "feature active"
                      : "feature"
                  }
                >
                  <div className="number">{(i + 1 + ( this.state.features ? this.state.features.length : 0))}</div>
                  {checkMoreActive(i) ? (
                    <p className="text" dangerouslySetInnerHTML={{__html:element.text}}></p>
                  ) : (
                    <div className="preview">
                      <div
                        onClick={() => this.handleClickMore( ( this.state.features ? this.state.features.length  + i: i))}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Plus
                          css={css`
                            color: var(--${this.state.color});
                            border-color: var(--greyColor);
                            &:hover {
                              color: white;
                              background: var(--${this.state.color});
                              border-color: var(--${this.state.color});
                            }
                          `}
                        />
                        <div style={{ paddingTop: 8, cursor: 'pointer' }}>Leggi di più</div>
                      </div>
                      <h3 dangerouslySetInnerHTML={{__html:element.title}} />
                    </div>
                  )}
                </div>
              ))
            : this.state.moreFeatures.map((element, i) => (
                <div
                  style={{
                    background: `var(--${this.state.color}`,
                    color: "#fff",
                  }}
                  className="feature"
                  key={i}
                >
                  <div className="preview">
                    <h3 style={{ paddingBottom: "1rem" }}>{element.title}</h3>
                    <p className="text" dangerouslySetInnerHTML={{__html:element.text}}></p>
                  </div>
                </div>
              ))}
        </div> }
        <div className="container">
          <IconContext.Provider
            value={{ color: "#fff", weight: "bold", size: 80 }}
          >
            {/* {this.state.banners && (
              <div style={{ marginBottom: 0 }}>
                <h6 className="pad-mobile">Progetti di comunicazione</h6>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: isMobile ? "column" : "row",
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.banners.map((banner) => (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: 16,
                        marginBottom: isMobile ? 16 : 40,
                      }}
                    >
                      <a href={banner.url} target="_blank">
                        <img src={banner.img} />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )} */}
            <div className="boxes-list pad-mobile">
             {this.state.boxes.map((element, i) =>
                <Box key={i} element={element} state={this.state} />
              )}
            </div>

            <div style={{ marginTop: 80 }}>
              <AreaListNew />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    );
  }
}

const formatSubsector = (text) => {
  if (!text) return "";
  const f = text.split("-").join(" ");
  return f[0].toUpperCase() + f.slice(1).toLowerCase();
};
