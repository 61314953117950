import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import IntroSection from "components/intro-section/IntroSection";
import { renderAttribute, filterAttribute } from "functions/renderAttribute";
import AttachmentButton from "components/buttons/attachmentButton";
import PrivateAttachmentButton from "components/buttons/privateAttachmentButton";
import Placeholders from "components/placeholders/Placeholders";

const format = (title) => {
  let arr = [];
  let exludeWords = ["e", "di"];
  arr = title.split("-");
  return arr
    .map((word, i) => {
      return exludeWords.includes(word) && i !== 0
        ? [word]
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export default class SingleDocument extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/Document",
        {
          documentId: id,
        }
      );
      const document = response?.data?.data?.document?.values;
      if (document) {
        this.setState({ document });
      }
    } catch (err) {
      console.log("Error CDM GiurisprudenzaDocument", err);
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <div id="single-document">
        <IntroSection
          category={format(this.props.match.url.split("/")[1])}
          subsector={format(this.props.match.url.split("/")[2])}
          subsubsector={format(this.props.match.url.split("/")[3])}
        />
        <div className="container pad-mobile">
          {this.state.loading ? (
            <Placeholders number={1} type="new" />
          ) : (
            <>
              <span className="date" style={{ marginBottom: 4 }}>
                Sentenza n° {renderAttribute(this.state.document, "NUMERO")} del{" "}
                {moment(renderAttribute(this.state.document, "DATA")).format(
                  "DD/MM/YYYY"
                )}{" "}
              </span>
              <span class="date">
                {renderAttribute(this.state.document, "ENTE")}
                {renderAttribute(this.state.document, "SEDE")
                  ? "- SEDE " + renderAttribute(this.state.document, "SEDE")
                  : ""}
                {renderAttribute(this.state.document, "SEZIONE")
                  ? " SEZIONE " +
                    renderAttribute(this.state.document, "SEZIONE")
                  : ""}
              </span>

              <h2 style={{ marginBottom: "1rem" }}>
                {renderAttribute(this.state.document, "OGGETTO")}
              </h2>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: renderAttribute(this.state.document, "ABSTRACT"),
                  }}
                />
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 8,
            }}
          >
            <RenderAllegato
              type="ALLEGATO_PUB"
              document={this.state.document}
            />
          </div>
        </div>
      </div>
    );
  }
}

class RenderAllegato extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 24,
          flexWrap: "wrap",
        }}
      >
        {" "}
        {this.props.document && (
          <>
            {!this.props.private
              ? filterAttribute(this.props.document, this.props.type) &&
                filterAttribute(this.props.document, this.props.type).map(
                  (allegato) => {
                    return (
                      <AttachmentButton
                        key={`allegato_${allegato?.value?.filePointer?.id ?? allegato?.value?.id}`}
                        id={allegato?.value?.filePointer?.id ? allegato?.value?.filePointer?.id : allegato.value.id}
                        text={
                          allegato.value.description
                            ? allegato.value.description
                            : allegato.value.file.name
                        }
                      />
                    );
                  }
                )
              : filterAttribute(this.props.document, this.props.type) &&
                filterAttribute(this.props.document, this.props.type).map(
                  (allegato) => {
                    return (
                      <PrivateAttachmentButton
                        id={allegato?.value?.filePointer?.id ? allegato?.value?.filePointer?.id : allegato.value.id}
                        text={
                          allegato.value.description
                            ? allegato.value.description
                            : allegato.value.file.name
                        }
                      />
                    );
                  }
                )}
          </>
        )}
      </div>
    );
  }
}
