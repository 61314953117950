import React, { Component } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoStatuto.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import Placeholders from "components/placeholders/Placeholders";
import { RenderAllegato } from "../../single-document/SingleDocument";
import { renderAttribute } from "functions/renderAttribute";

function add() {
  this.classList.toggle("show");
}
export default class ChiSiamoStatuto extends Component {
  state = { loading: true };

  async componentDidMount() {
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\CHI_SIAMO",
          folder: "STATUTO",
          documentValueOrders: [
            {
              code: "TITOLO",
              type: "1",
              direction: "asc",
            },
          ],
        }
      );

      this.setState({
        statuto:
          response?.data?.data?.documents &&
          response?.data?.data?.documents[0]?.values,
      });
    } catch (err) {
      console.log("Error CDM ChiSiamoStatuto", err);
    }

    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps, oldState) {
    if (
      oldState.loading !== this.state.loading &&
      !this.state.loading &&
      !!this.state.statuto
    ) {
      var elm = document.querySelectorAll("#statuto .list > ul > li");
      for (var i = 0; i < elm.length; i++) {
        elm[i].addEventListener("click", add);
        elm[i].setAttribute("number", i + 1);
      }
    }
  }

  render() {
    return (
      <div id="statuto">
        <Helmet>
          <title>Utilitalia - Statuto</title>
          <meta
            name="description"
            content="In questa sezione è disponibile lo statuto di Utilitalia; è possibile visualizzarne i contenuti navigando attraverso le aree di interesse"
          />
        </Helmet>
        <IntroChiSiamo section="Statuto" background="blue" />
        <div className="container pad-mobile">
          <h2
            className="general"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Statuto{" "}
            <RenderAllegato
              type="ALLEGATO_PRINCIPALE"
              document={this.state.statuto}
            />
          </h2>

          {this.state.loading ? (
            <Placeholders number={4} type="statuto" />
          ) : (
            this.state.statuto && (
              <div
                className="list"
                dangerouslySetInnerHTML={{
                  __html: renderAttribute(this.state.statuto, "TESTO"),
                }}
              />
            )
          )}
        </div>
      </div>
    );
  }
}
