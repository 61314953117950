import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { ReactComponent as Download } from "icons/file-arrow-down-bold.svg";
import Spinner from "components/spinner/Spinner";
import { Link } from "react-router-dom";

export default class attachmentButton extends Component {
  state = { loading: false };
  fetchPdf = async (id) => {
    this.setState({ loading: true, showforbidden: false });
    const sessionId = sessionStorage.getItem("sessionId");
    const headers = {
      headers: { "Session-Id": sessionId },
    };
    let status = null;
    try {
      let file = await axios.post(
        "https://api.utilitalia.it/DMS/Files/File",
        {
          fileId: id,
        },
        headers
      );
      status = file?.data?.status;
      file = file.data && file.data.data;
      if (file) {
        this.setState({ pdf: file.data, pdfName: file.file && file.file.name });
      } else if (isMobile) {
        this.setState({ error: true });
      } else {
        if(status === 'FORBIDDEN') {
            this.setState({ loading: false, showforbidden: true });
        } else {
            this.props.history.push("/login");
        }   
      }
    } catch (err) {
      this.setState({ error: true });
    }
    if( status === 'FORBIDDEN') {
         this.setState({ loading: false,  showforbidden: true });
     } else {
         this.setState({ loading: false,  showforbidden: false });
     }
   
    
  };

  // getFileType(pdfName) {
  //   const type = pdfName && pdfName.split(".");
  //   if (type) {
  //     switch (type.reverse()[0]) {
  //       case "pdf":
  //         return "application/pdf";
  //       case "docx":
  //         return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  //       case "xls":
  //         return "application/vnd.ms-excel";
  //       case "xlsx":
  //         return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //       case "pptx":
  //         return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  //       default:
  //         return "application/pdf";
  //     }
  //   }
  //   return null;
  // }

  // handlePdf = async (id) => {
  //   window.location.href = `/pdf/${id}`;
  // };

  render() {
    return (
      <div
        style={{
          marginLeft: 8,
          cursor: "pointer",
          marginBottom: 8,
          alignSelf: this.props.end && "flex-end",
        }}
        className="btn file"
      >
        {this.state.loading&& !this.state.showforbidden  ? (
          <Spinner btnSpinner={true} />
        ) : (
          // <div
          //   onClick={() => this.handlePdf(this.props.id, this.props.video)}
          //   style={{ display: "flex", alignItems: "center" }}
          // >
          //   <Download />
          //   {this.props.text}
          // </div>
          <>
            { this.state.showforbidden ? <p>Non si dispone di privilegi sufficienti per accedere al contenuto richiesto</p>  : <Link
              to={`/pdf/${this.props.id}`}
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <Download />
              {this.props.text}
            </Link> }
          </>
        )}
        { !this.state.loading && this.state.showforbidden && <p>Non si dispone di privilegi sufficienti per accedere al contenuto richiesto</p> }
      </div>
    );
  }
}

export class AttachmentDifferentFormatButton extends Component {
  state = { loading: false };
  // componentDidMount() {
  //   this.fetchPdf(this.props.id);
  // }

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.error !== oldState.error && this.state.error === true) {
      this.props.history.push("/login");
    }
  };

  fetchPdf = async (id) => {
    this.setState({ loading: true, showforbidden: false });
    const sessionId = sessionStorage.getItem("sessionId");
    const headers = {
      headers: { "Session-Id": sessionId },
    };
    let status = null;
    try {
      let file = await axios.post(
        "https://api.utilitalia.it/DMS/Files/File",
        {
          fileId: id,
        },
        headers
      );
      status = file?.data?.status;
      file = file.data?.data;

      if (file) {
        // console.log("file", file);
        const blob = this.b64toBlob(
          file.data,
          this.getFileType(file.file?.name)
        );
        const blobUrl = URL.createObjectURL(blob);

        this.setState({ pdf: blobUrl, pdfName: file.file?.name });
        const linkSource = blobUrl;
        const downloadLink = document.createElement("a");
        const fileName = file.file?.name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else if (isMobile) {
        this.setState({ error: true });
      } else {
        if(status === 'FORBIDDEN') {
            this.setState({ loading: false, showforbidden: true });
        } else {
            this.props.history.push("/login");
        } 
      }
    } catch (err) {
      this.setState({ error: true, loading: false, showforbidden: false  });
    }

    if( status === 'FORBIDDEN') {
         this.setState({ loading: false,  showforbidden: true });
     } else {
         this.setState({ loading: false,  showforbidden: false });
     }
  };

  getFileType = (pdfName) => {
    const type = pdfName.split(".");
    switch (type[type.length - 1]) {
      case "pdf":
        return "application/pdf";

      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";

      default:
        return "application/pdf";
    }
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  render() {
    // console.log("PDF", this.state);
    return (
      <div
        style={{
          marginLeft: 8,
          cursor: "pointer",
          marginBottom: 8,
          alignSelf: this.props.end && "flex-end",
        }}
        className="btn file"
      >
        {this.state.loading  && !this.state.showforbidden ? (
          <Spinner btnSpinner={true} />
        ) : (
          <>
            { this.state.showforbidden && <p>Non si dispone di privilegi sufficienti per accedere al contenuto richiesto</p> }
            {this.props.text.includes("pdf") && !this.state.showforbidden ? (
              <Link
                to={`/pdf/${this.props.id}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <Download />
                {this.props.text}
              </Link>
            ) : (
              <>{ !this.state.showforbidden && <div
                onClick={() => this.fetchPdf(this.props.id)}
                key={`link_${this.props.id}`}
                className="btn"
                style={{ marginLeft: 8 }}
                download
                target="_blank"
                rel="noreferrer"
              >
                <Download width={16} height={16} style={{ marginRight: 4 }} />
                {this.props.text}
              </div> }</>
            )}
          </>
        )}
      </div>
    );
  }
}
