import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./GeneralList.scss";
import axios from "axios";
import moment from "moment";
import IntroSection from "components/intro-section/IntroSection";
import Filter from "components/filter/Filter";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import Placeholders from "components/placeholders/Placeholders";

const format = (title) => {
  if (title) {
    let arr = [];
    let exludeWords = ["e", "di"];
    arr = title.split("-");
    return arr
      .map((word, i) => {
        return exludeWords.includes(word) && i !== 0
          ? [word]
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  } else {
    return null;
  }
};

const renderAttribute = (array, code) => {
  return (
    array &&
    array.find((value) => value.code === code) &&
    array.find((value) => value.code === code).value
  );
};

const renderFunction = (pathname) => {
  switch (pathname) {
    case "/energia/bollettino-finanziamenti/list": {
      return {
        title: "Bollettino <span>Finanziamenti</span>",
        documentClasses: "AREA_ENERGIA_FOCUS",
        headingPath: "\\AREA_ENERGIA",
        filter: "\\AREA_ENERGIA\\FOCUS",
      };
    }
    case "/ambiente/studi-e-ricerche/list": {
      return {
        title: "Studi e <span>Ricerche</span>",
        documentClasses: "STUDIO/RICERCA",
        headingPath: "\\AREA AMBIENTE",
        filter: "\\AREA AMBIENTE\\STUDI/RICERCHE",
        folder: moment().year(),
      };
    }
    case "/acqua/studi-e-ricerche/list": {
      return {
        title: "Studi e <span>Ricerche</span>",
        documentClasses: "STUDIO/RICERCA",
        headingPath: "\\AREA ACQUA",
        filter: "\\AREA ACQUA\\STUDI/RICERCHE",
        folder: moment().year(),
      };
    }
    case "/ambiente/csr-sostenibilita/studi-e-documentazione/list":
    case "/direzione-generale/csr-sostenibilita/studi-e-documentazione/list": {
      return {
        title: "Studi e <span>Documentazione</span>",
        documentClasses: "STUDIEDOCUMENTAZIONE",
        heading: "\\AREA AMBIENTE\\SOSTENIBILITA\\STUDIEDOCUMENTAZIONE",
        filter: false,
      };
    }
    case "/ambiente/csr-sostenibilita/bilanci-sostenibilita/list":
    case "/direzione-generale/csr-sostenibilita/bilanci-sostenibilita/list": {
      return {
        title: "Bilanci di <span>Sostenibilità</span>",
        documentClasses: "BILANCISOSTENIBILITA",
        heading: "\\AREA AMBIENTE\\SOSTENIBILITA\\BILANCISOSTENIBILITA",
        filter: "\\AREA AMBIENTE\\SOSTENIBILITA\\BILANCISOSTENIBILITA",
      };
    }
    case "/ambiente/csr-sostenibilita/convegni-e-seminari/list":
    case "/direzione-generale/csr-sostenibilita/convegni-e-seminari/list": {
      return {
        title: "Convegni e <span>Seminari</span>",
        documentClasses: "CONTENUTO_WEB",
        heading: "\\AREA AMBIENTE\\SOSTENIBILITA\\CONVEGNI_SEMINARI",
      };
    }
    case "/ambiente/csr-sostenibilita/attivita-gdl/resoconti-riunioni/list":
    case "/direzione-generale/csr-sostenibilita/attivita-gdl/resoconti-riunioni/list": {
      return {
        title: "Resoconti e <span>Riunioni</span>",
        documentClasses: "RESOCONTO_RIUNIONI",
        heading:
          "\\AREA AMBIENTE\\SOSTENIBILITA\\ATTIVITAGDL\\RESOCONTO_RIUNIONI",
      };
    }
    case "/ambiente/csr-sostenibilita/attivita-gdl/area-documentale/list":
    case "/direzione-generale/csr-sostenibilita/attivita-gdl/area-documentale/list": {
      return {
        title: "Area <span>Documentale</span>",
        documentClasses: "AREA_DOCUMENTALE",
        heading:
          "\\AREA AMBIENTE\\SOSTENIBILITA\\ATTIVITAGDL\\AREA_DOCUMENTALE",
      };
    }
    case "/ambiente/csr-sostenibilita/attivita-gdl/comunicazioni-gdl/list":
    case "/direzione-generale/csr-sostenibilita/attivita-gdl/comunicazioni-gdl/list": {
      return {
        title: "Comunicazioni al <span>GDL</span>",
        documentClasses: "COMUNICAZIONI_GDL",
        heading:
          "\\AREA AMBIENTE\\SOSTENIBILITA\\ATTIVITAGDL\\COMUNICAZIONI_GDL",
      };
    }
    case "/area-affari-regolatori/norme-e-regole/antitrust/list": {
      return {
        title: "Antitrust",
        documentClasses: "NEWS",
        heading: "\\AREA AFFARI REGOLATORI\\NORME_REGOLE\\ANTITRUST",
      };
    }
    case "/area-affari-regolatori/norme-e-regole/ue/list": {
      return {
        title: "UE",
        documentClasses: "NEWS",
        heading: "\\AREA AFFARI REGOLATORI\\NORME_REGOLE\\UE",
        filter: true,
      };
    }
    case "/area-affari-regolatori/norme-e-regole/acqua/list": {
      return {
        title: "Acqua",
        documentClasses: "NEWS",
        heading: "\\AREA AFFARI REGOLATORI\\NORME_REGOLE\\ACQUA",
        filter: true,
      };
    }
    case "/area-affari-regolatori/norme-e-regole/energia/list": {
      return {
        title: "Energia",
        documentClasses: "NEWS",
        heading: "\\AREA AFFARI REGOLATORI\\NORME_REGOLE\\ENERGIA",
        filter: true,
      };
    }
    case "/area-affari-regolatori/norme-e-regole/ambiente/list": {
      return {
        title: "Ambiente",
        documentClasses: "NEWS",
        heading: "\\AREA AFFARI REGOLATORI\\NORME_REGOLE\\AMBIENTE",
        filter: true,
      };
    }
    case "/area-lavoro-relazioni-industriali/normativa/list": {
      return {
        title: "Normativa",
        documentClasses: "NORMATIVA",
        heading: "\\AREA LAVORO\\NORMATIVA",
        filter: true,
        tipo: true,
      };
    }
    case "/area-lavoro-relazioni-industriali/contratti/list": {
      return {
        title: "Contratti",
        documentClasses: "CONTRATTOLAV",
        headingPath: "\\AREA LAVORO\\CONTRATTI_NEW",
        heading: "",
        filter: true,
      };
    }
    case "/area-lavoro-relazioni-industriali/dati-statistici/list": {
      return {
        title: "Dati <span>Statistici</span>",
        documentClasses: "DATISTATISTICI",
        heading: "\\AREA LAVORO\\DATISTAT",
        filter: "\\AREA LAVORO\\DATISTAT",
      };
    }
    // case "/area-giuridico-legislativa-fiscale/dal-parlamento/list": {
    //   return {
    //     title: "Dal <span>Parlamento</span>",
    //     documentClasses: "PARLAMENTO",
    //   };
    // }
    case "/area-giuridico-legislativa-fiscale/norme-e-regole/list": {
      return {
        title: "Norme e <span>Regole</span>",
        documentClasses: "NORMEREGOLE",
        heading: "\\GL\\NORMEREGOLE",
        folder: "GENERALE",
        filter: true,
      };
    }
case "/affari-istituzionali-ed-europei/norme-e-regole/ue/list": {
      return {
        title: "Norme e <span>Regole</span>",
        documentClasses: "NORMEREGOLE",
        heading: "\\GL\\NORMEREGOLE",
        folder: "GENERALE",
        filter: true,
        documentValueFilters:[{"code":"TIPOLOGIA","type":"1","start":"Norme e Regole UE"}]
      };
    }
    case "/area-giuridico-legislativa-fiscale/norme-e-regole/ue/list": {
      return {
        title: "Norme e <span>Regole</span>",
        documentClasses: "NORMEREGOLE",
        heading: "\\GL\\NORMEREGOLE",
        folder: "GENERALE",
        filter: true,
        documentValueFilters:[{"code":"TIPOLOGIA","type":"1","start":"Norme e Regole UE"}]
      };
    }
    case "/area-giuridico-legislativa-fiscale/fiscale-e-tributario/list": {
      return {
        documentClasses: "DATISTATISTICI",
        heading: "\\AREA LAVORO\\CONTRATTI_NEW",
      };
    }
    case "/area-giuridico-legislativa-fiscale/appalti-e-concessioni/norme-e-regole/list": {
      return {
        title: "Norme e <span>Regole</span>",
        documentClasses: "NORMEREGOLE",
        heading: "\\GL\\NORMEREGOLE",
        folder: "APPALTIECONCESSIONI",
        filter: "normativa",
      };
    }
    case "/area-comunicazione/focus-on/list": {
      return {
        title: "Focus<span>On</span>",
        documentClasses: "FOCUSON",
        headingPath: "\\COM",
      };
    }
    case "/direzione-generale/notizie-antitrust/list": {
      return {
        title: "Notizie <span>Antitrust</span>",
        documentClasses: "NEWSALERTANTITRUS",
        heading: "\\GL\\NEWSALERTANTITRUST",
      };
    }
    case "/area-lavoro-relazioni-industriali/giurisprudenza/list": {
      return {
        title: "Giurisprudenza",
        documentClasses: "GIURISPRUDENZA_NEW",
        heading: "\\AREA LAVORO\\GIURISPRUDENZA_NEW",
      };
    }
    case "/pubblicazioni/list": {
      return {
        title: "Pubblicazioni",
        documentClasses: "PUBBLICAZIONI",
        heading: "\\ATTIVITA\\PUBBLICAZIONI",
      };
    }
    case "/area-strategie-pianificazione/studi-e-documentazione/list": {
      return {
        title: "Area Strategie e Pianificazione",
        documentClasses: "STUDIO/RICERCA",
        heading: "\\SPS\\STUDI_RICERCHE",
        filter: "\\SPS\\STUDI_RICERCHE",
        folder: moment().year(),
        documentValueDefs: [
          {
            code: "TITOLO, DATA ,ALLEGATO_PUBBLICO",
          },
        ],
      };
    }
    default:
  }
};

export default class GeneralList extends Component {
  state = {
    loading: true,
    active: {},
    list: [],
    folders: [],
    classesFilter: [],
    valuesFilter: [],
    from: 1,
  };

  componentDidMount() {
    this.renderSetup(this.props.match.url);
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.props.match.params.sector !== oldProps.match.params.sector ||
      this.props.match.url !== oldProps.match.url
    ) {
      this.renderSetup(this.props.match.url);
    }
    if (
      this.state.from !== oldState.from ||
      this.state.active !== oldState.active
    ) {
      this.setState({ loading: true });
      // salvare nel session storage
      sessionStorage.setItem(
        "general_list_filter",
        JSON.stringify({
          ...this.state.active,
          pathKey: this.props.location.pathname,
        })
      );
      try {
        const response = await this.fetchList(
          renderFunction(this.props.match.url)?.documentClasses,
          this.state.active.tipologia
            ? null
            : renderFunction(this.props.match.url)?.headingPath,
          this.state.active.tipologia
            ? `${renderFunction(this.props.match.url)?.headingPath}\\${
                this.state.active.tipologia
              }`
            : renderFunction(this.props.match.url)?.heading,
          this.state.active.folder,
          this.state.active.value
        );
        const list = response?.data?.data?.documents;

        
        if (list && list.length === 0) {
          this.setState({ from: 1, list });
        } else {
          this.setState({
            list,
          });
        }
        window.scrollTo({ top: 370, left: 0, behavior: "smooth" });
      } catch (err) {
        console.log("Error CDU GeneralList", err);
      }
      this.setState({ loading: false });
    }
    if (
      this.state.active.tipologia !== oldState.active.tipologia &&
      this.props.match.url ===
        "/area-lavoro-relazioni-industriali/contratti/list"
    ) {
      this.setState({ loading: true });
      await this.fetchFilter1(
        `${renderFunction(this.props.match.url)?.headingPath}${
          this.state.active.tipologia ? `\\${this.state.active.tipologia}` : ""
        }`
      );
      this.setState({ loading: false });
    }
  }

  renderSetup = async (type) => {
    this.setState({ loading: true });

    const bakValue = sessionStorage.getItem("general_list_filter");
    if (bakValue) {
      const bakState = JSON.parse(bakValue);
      if (bakState.pathKey !== this.props.location.pathname) {
        sessionStorage.removeItem("general_list_filter");
      } else {
        const copy = { ...bakState };
        delete copy.pathKey;
        if (Object.keys(copy).length) {
          this.setState({
            ...this.state,
            active: bakState,
          });
        }
      }
    }

    switch (type) {
      case "/area-giuridico-legislativa-fiscale/appalti-e-concessioni/norme-e-regole/list":
      case "/area-giuridico-legislativa-fiscale/norme-e-regole/list": {
        try {
          await this.fetchFilter2(
            renderFunction(this.props.match.url)?.documentClasses
          );
        } catch (err) {
          console.log("Error fetching filter2", err);
        }
        break;
      }
      case "/area-affari-regolatori/norme-e-regole/acqua/list":
      case "/area-affari-regolatori/norme-e-regole/ambiente/list":
      case "/area-affari-regolatori/norme-e-regole/energia/list": {
        try {
          await this.fetchFilter3(
            renderFunction(this.props.match.url)?.heading
          );
          await this.fetchFilter1(
            renderFunction(this.props.match.url)?.heading,
            "alphabeticalOrder"
          );
        } catch (err) {
          console.log("Error fetching filter3 / 1", err);
        }
        break;
      }
      case "/area-lavoro-relazioni-industriali/normativa/list": {
        try {
          await this.fetchFilter1(
            renderFunction(this.props.match.url)?.heading
          );
          await this.fetchFilter4(
            renderFunction(this.props.match.url)?.documentClasses
          );
        } catch (err) {
          console.log("Error fetching filter1 / 4", err);
        }
        break;
      }
      case "/area-lavoro-relazioni-industriali/contratti/list": {
        try {
          const classes = await this.fetchFilter5(
            renderFunction(this.props.match.url)?.headingPath
          );
          // const bak = localStorage.getItem("general_list_filter")
          //   ? JSON.parse(localStorage.getItem("general_list_filter"))
          //   : null;
          // await this.fetchFilter1(
          //   `${renderFunction(this.props.match.url)?.headingPath}\\${
          //     bak?.tipologia || (classes?.length ? classes[0] : "")
          //   }`
          // );
        } catch (err) {
          console.log("Error fetching filter3 / 1", err);
        }
        break;
      }
      default: {
        try {
          await this.fetchFilter1(renderFunction(this.props.match.url)?.filter);
        } catch (err) {
          console.log("Error fetching filter1 default", err);
        }
        break;
      }
    }

    try {
      let response = await this.fetchList(
        // renderFunction(this.props.match.url)?.documentClasses,
        // renderFunction(this.props.match.url)?.headingPath,
        // renderFunction(this.props.match.url)?.heading,
        // renderFunction(this.props.match.url)?.folder
        renderFunction(this.props.match.url)?.documentClasses,
        this.state.active.tipologia
          ? null
          : renderFunction(this.props.match.url)?.headingPath,
        this.state.active.tipologia
          ? `${renderFunction(this.props.match.url)?.headingPath}\\${
              this.state.active.tipologia
            }`
          : renderFunction(this.props.match.url)?.heading,
        this.state.active.folder,
        this.state.active.value
      );
      response = response?.data?.data;
      if (response) {
        this.setState({
          pages: response?.totalPages,
          list: response?.documents,
        });
      }
    } catch (err) {
      console.log("Error rendering setup GeneralList", err);
    }
    this.setState({ loading: false });
  };

  fetchList = async (
    documentClasses,
    headingPath,
    heading,
    folder,
    filter,
    tipo,
    documentValueFilters
  ) => {
    return await axios.post("https://api.utilitalia.it/DMS/Documents/List", {
      documentClasses: [documentClasses],
      headingPath: headingPath || "",
      heading: heading || "",
      folder: folder,
      pageStart: this.state.from,
      pageSize: 10,
      documentValueOrders: [
        {
          code: "DATA",
          type: "5",
          direction: "desc",
        },
      ],
      documentValueFilters: documentValueFilters || [
        {
          code: tipo ? "TIPO" : "TIPOLOGIA",
          type: "1",
          start: filter,
        },
      ],
    });
  };

  fetchFilter1 = async (path, alphabeticalOrder) => {
    let folders = await axios.post(
      "https://api.utilitalia.it/DMS/Folders/List",
      {
        path: path,
        // orderBy: "name",
        // orderDirection: "asc",
      }
    );
    folders = folders?.data?.data?.folders || [];
    folders = folders.map((element) => {
      return element.code;
    });
    // console.log("alphabeticalOrder", alphabeticalOrder);
    if (!!alphabeticalOrder) {
      // console.log("dIWq");
      folders.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    } else {
      folders.sort((a, b) => (a > b ? -1 : b > a ? 1 : 0));
    }

    this.setState({ folders: folders });
  };

  fetchFilter2 = async (documentClasses) => {
    let value = await axios.post(
      "https://api.utilitalia.it/DMS/Documents/Lookup",
      {
        documentClasses: [documentClasses],
        documentValueDef: {
          code: "TIPOLOGIA",
          type: "1",
        },
      }
    );
    value = value.data.data.values;

    this.setState({ valuesFilter: value });
  };

  fetchFilter4 = async (documentClasses) => {
    let value = await axios.post(
      "https://api.utilitalia.it/DMS/Documents/Lookup",
      {
        documentClasses: [documentClasses],
        documentValueDef: {
          code: "TIPO",
          type: "1",
        },
      }
    );
    value = value.data.data.values;

    this.setState({ valuesFilter: value });
  };

  fetchFilter3 = async (heading) => {
    let classes = await axios.post(
      "https://api.utilitalia.it/DMS/DocumentClasses/List",
      {
        heading: heading,
      }
    );
    classes = classes.data.data.classes;

    classes = classes.map((element) => {
      return element.code;
    });

    this.setState({ classesFilter: classes });

    return classes;
  };

  fetchFilter5 = async (heading) => {
    // console.log("heading", heading);
    let classes = await axios.post(
      "https://api.utilitalia.it/DMS/Headings/List",
      {
        path: heading,
        deep: 1,
      }
    );
    classes = classes.data.data.headings;

    classes = classes.map((element) => {
      return element.code;
    });

    this.setState({ classesFilter: classes });

    return classes;
  };

  handleChange = async (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleReset = () => {
    this.setState({ active: {} });
  };

  handleNavigation = (delta) => {
    this.setState({ from: this.state.from + delta * 10 });
  };

  render() {
    const filters = [
      {
        name: "tipologia",
        values: this.state.classesFilter,
      },
      {
        name: "folder",
        values: this.state.folders,
      },
      {
        name: "value",
        values: this.state.valuesFilter,
      },
    ];
    return (
      <div>
        {this.props.match.url === "/pubblicazioni/list" && (
          <Helmet>
            <title>Utilitalia - Pubblicazioni</title>
            <meta
              name="description"
              content="In questa sezione trovi tutte le pubblicazioni di Utilitalia. Visita il sito "
            />
          </Helmet>
        )}
        {this.props.match.url !== "/pubblicazioni/list" && (
          <IntroSection
            category={format(this.props.match.url.split("/")[1])}
            subsector={format(this.props.match.url.split("/")[2])}
            subsubsector={format(this.props.match.url.split("/")[3])}
            subsubsubsector={format(this.props.match.url.split("/")[4])}
          />
        )}
        <div className="container pad-mobile">
          <h2
            className="general"
            dangerouslySetInnerHTML={{
              __html:
                renderFunction(this.props.match.url) &&
                renderFunction(this.props.match.url).title,
            }}
          />
          {renderFunction(this.props.match.url) &&
            renderFunction(this.props.match.url).filter && (
              <Filter
                filters={filters}
                value={this.state.active}
                change={this.handleChange}
                reset={this.handleReset}
              />
            )}
          <div className="general-list">
            {this.state.loading ? (
              <Placeholders number={3} type="new" />
            ) : !this.state.list || this.state.list?.length === 0 ? (
              <p>Nessun risultato</p>
            ) : (
              this.state.list.map((element, i) => {
                const id = element?.talkingId !== null && element?.talkingId.trim() !== '' && element.talkingId !== false ? element?.talkingId : element?.id;
                return (
                  <div className="item" key={i}>
                    <div className="left">
                      <span className="date">
                        {/* {renderAttribute(element.values, "DATA")} */}
                        {renderAttribute(element.values, "CITTA")
                          ? renderAttribute(element.values, "AZIENDA") +
                            " - " +
                            renderAttribute(element.values, "CITTA")
                          : renderAttribute(element.values, "NUMEROATTO")
                          ? renderAttribute(element.values, "NUMEROATTO")
                          : moment(
                              renderAttribute(element.values, "DATA")
                            ).format("ll")}
                      </span>
                      <h4>
                        {renderAttribute(element.values, "NUMERO_FOCUS") &&
                          renderAttribute(element.values, "NUMERO_FOCUS") + " "}
                        {renderAttribute(element.values, "ANNO")
                          ? renderAttribute(element.values, "TITOLO").concat(
                              " - " + renderAttribute(element.values, "ANNO")
                            )
                          : renderAttribute(element.values, "TITOLO")}
                        {renderAttribute(element.values, "NOMEATTO")}
                      </h4>
                    </div>
                    <Link
                      to={
                        "/" +
                        this.props.match.url.split("/")[1] +
                        "/" +
                        this.props.match.url.split("/")[2] +
                        "/list/" +
                        id
                      }
                      className="btn"
                    >
                      Leggi di più
                    </Link>
                  </div>
                )})
            )}
          </div>
          {this.state.pages > 1 && (
            <NavigationCircle
              function={this.handleNavigation}
              color="var(--greyDarkColor)"
              disabled={this.state.from === 1}
            />
          )}
        </div>
      </div>
    );
  }
}
