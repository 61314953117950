/** @jsxImportSource @emotion/react */
import "./AreaList.scss";
import axios from "axios";
import { css } from "@emotion/react";
import { cx } from "@emotion/css";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import { Link } from "react-router-dom";
import { useState, Fragment, useEffect } from 'react';
import AreaListDirezione from "components/area-list/AreaListDirezione";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Timer } from "icons/timer-bold.svg";
const tiles = [
  {
    title: "Acqua",
    icon: <Water />,
    key: 'acqua',
    href: "/acqua/home",
    color: "linear-gradient(135deg, #0066a8 0%, #0a85bf 50%, #279fca 100%)",
    html: "<header><img src='/files/img/Tania_Tellini.jpeg' alt='Tania Tellini' width='60' height='60' /><div><h3>Direttore</h3><p>Tania Tellini</p></div></header><h3>Collaboratori</h3><p>Marco Gatta<br/>Elena Mauro<br/>Ada Polizzi</p>"
  },
  {
    title: "Ambiente",
    icon: <Leaf />,
    key: 'ambiente',
    href: "/ambiente/home",
    color: "linear-gradient(135deg, #398d99 0%, #36b29b 50%, #06c96d 100%)",
    html: "<header><img src='/files/img/Luca_Mariotto.jpeg' alt='Luca Mariotto' width='60' height='60' /><div><h3>Direttore</h3><p>Luca Mariotto</p></div></header><h3>Collaboratori</h3><p>Maria Carmina Glorioso<br/>Bernardo Piccioli Fioroni<br/>Riccardo Viselli</p>"
  },
  {
    title: "Energia",
    icon: <Thunder />,
    key: 'energia',
    href: "/energia/home",
    color: "linear-gradient(135deg, #ff8801 0%, #ffbd0d 50%, #eb650c 100%)",
    html: "<header><img src='/files/img/Mattia_Sica.jpeg' alt='Mattia Sica' width='60' height='60' /><div><h3>Direttore</h3><p>Mattia Sica</p></div></header><h3>Collaboratori</h3><p>Eleonora Capaccioli<br/>Francesco Vitolo</p>"
  },
];

const subareas = [
    {
    title: "Affari istituzionali ed europei",
    color: 'var(--affari)',
    key: 'affari-istituzionali-ed-europei',
    //icon: <Book />,
    //to: "/direzione-generale/home",
    html: "<h3>Responsabile</h3><p>Giulia Chieffo</p><h3>Collaboratori</h3><p>Luigi Joseph Del Giacco<br/>Damiano Ruffini</p>",
    to: "/affari-istituzionali-ed-europei/home/"
  },
  {
    title: "Amministrazione",
    color: 'var(--amministrazione)',
    key: 'amministrazione',
    //icon: <Book />,
    to: "/amministrazione/home",
     html: "<h3>Responsabile</h3><p>Antonella Balbi</p><h3>Collaboratori</h3><p>Lorenzo Di Marco<br/>Paola Mazzone<br/>Patrizia Montanaro</p>"
  },
  {
    title: "Direzione e Organizzazione interna",
    color: 'var(--blueColor)',
    key: 'organizzazione-e-gestione-interna',
    //icon: <Book />,
    to: "/direzione-generale/organizzazione-e-gestione-interna/home",
     html: "<h3>Responsabile</h3><p>Giovanna Nesticò</p><h3>Coordinatore Presidenza, Direzione</h3><p>Fiorella Lettieri</p><h3>Coordinatore Organi Statutari</h3><p>Francesca Coppola</p><h3>Collaboratori</h3><p>Paolo Carrubba<br/>Andrea De Angelis<br/>Elisa Pecoraro</p><h3>Staff di segreteria (Aree/Settori)</h3><p>Coordinatore: Alessandra Macrina<br/>Marina Bellantoni<br/>Simona Cirillo</p>"
  },
  {
    title: "Eventi e Convegni",
    color: 'var(--comunicazione)',
    key: 'eventi-convegni',
    //icon: <Book />,
    //to: "/direzione-generale/home",
    to: "/eventi-convegni/home/",
     html: "<h3>Responsabile</h3><p>Gloria Giombini</p><h3>Collaboratori</h3><p>Mauro Antonioli<br/>Riccardo Perri</p>"
  },
  {
    title: "Media Relations",
    color: 'var(--strategie)',
    key: 'media-relations',
    //icon: <Chat />,
    to: "/media-relations/home/",
     html: "<h3>Responsabile</h3><p>Domenico Zaccaria</p><h3>Collaboratori</h3><p>Mauro Antonioli<br/>Dario Tersigni</p>"
  },
  {
    title: "Servizio funerario",
    color: 'var(--funerario)',
    to: '/servizio-funerario/home/',
    key: 'servizio-funerario',
    //icon: <Chat />,
    //to: "/area-comunicazione/home",
     html: "<h3>Responsabile</h3><p>Valeria Leotta</p>"
  },
  {
    title: "Servizi IT e multimediali",
    color: 'var(--comunicazione)',
    key:'servizi-it-multimediali',
    to: "/servizi-it-multimediali/home/",
    //icon: <Chat />,
     html: "<h3>Coordinatore</h3><p>Tiziano Gallo</p><h3>Collaboratori</h3><p>Mauro Antonioli<br/>Paolo Carrubba</p>"
  },
];

const areas = [
  // {
  //   empty: true,
  // },
  {
    empty: false,
    title: "Area affari regolatori",
    icon: <Settings />,
    openSubAreas: false,
    key: 'area-affari-regolatori',
    color: 'var(--affari)',
    to: "/area-affari-regolatori/home",
    html: "<header><img src='/files/img/Francesca_Mazzarella.jpeg' alt='Francesca Mazzarella' width='60' height='60' /><div><h3>Direttore ad interim</h3><p>Francesca Mazzarella</p></div></header><h3>Collaboratori</h3><p>Fabrizio Falconi<br/>Samanta Meli<br/>Carmen Monaco<br/>Gaia Rodriquez</p>"
  },
  {
    empty: false,
    title: "Area lavoro e relazioni industriali",
    color: 'var(--lavoro)',
    icon: <Bag />,
    key: 'area-lavoro-relazioni-industriali',
    openSubAreas: false,
    to: "/area-lavoro-relazioni-industriali/home",
    html: "<header><img src='/files/img/Paola_Giuliani.jpeg' alt='Paola Giuliani' width='60' height='60' /><div><h3>Direttore</h3><p>Paola Giuliani</p></div></header><h3>Collaboratori</h3><p>Ilenia Casanova<br />Francesco Gioscia<br/>Lorenzo Lama<br/>Martino Moioli<br/>Simona Saccomanno</p>"
  },
  {
    empty: false,
    title: "Area giuridico legislativa e fiscale",
    color: 'var(--giuridico)',
    icon: <Steam />,
    openSubAreas: false,
    key: 'area-giuridico-legislativa-fiscale',
    to: "/area-giuridico-legislativa-fiscale/home",
    html: "<header><img src='/files/img/Giulia_Chieffo.jpeg' alt='Giulia Chieffo' width='60' height='60' /><div><h3>Direttore</h3><p>Giulia Chieffo</p></div></header><h3>Collaboratori</h3><p>Pina Russo<br/>Margherita Tria</p>"
  },
  {
    empty: false,
    title: "Area Strategie e Pianificazione di settore",
    color: 'var(--strategie)',
    icon: <Target />,
    openSubAreas: false,
    key: 'area-strategie-pianificazione',
    to: "/area-strategie-pianificazione/home",
    html: "<header><img src='/files/img/Francesca_Mazzarella.jpeg' alt='Francesca Mazzarella' width='60' height='60' /><div><h3>Direttore</h3><p>Francesca Mazzarella</p></div></header><h3>Collaboratori</h3><p>Andrea Di Piazza<br/>Mattia Galipò<br/>Valeria Zingarelli</p>"
  },
  // {
  //   empty: false,
  //   title: "Direzione generale",
  //   color: 'var(--strategie)',
  //   icon: <Target />,
  //   to: "/direzione-generale/home",
  //   openSubAreas: true,
  //   html: "<header><div><h3>Direttore</h3></div></header>"
  // },
  // {
  //   empty: true,
  // },
];

const setSector = (address) => {
    const settori = {
        'area-affari-regolatori': 'Area Affari Regolatori',
        "area-giuridico-legislativa-fiscale":'Area Giuridico-Legislativa e Fiscale',
        'area-strategie-pianificazione': 'Area Strategie e Pianificazione di settore',
        'area-lavoro-relazioni-industriali' : 'Area Lavoro e Relazioni Industriali',
        'acqua' : 'Settore Acqua',
        'ambiente' : 'Settore Ambiente',
        'energia' : "Settore Energia",
        'amministrazione' : 'Amministrazione',
        'servizi-it-multimediali' : 'Servizi IT e Multimediali',
        'servizio-funerario' : 'Sefit Utilitalia – Servizi Funerari Italiani',
        'media-relations' : 'Media Relations',
        'eventi-convegni' : 'Eventi e Convegni',
        'direzione-generale' : 'DIREZIONE GENERALE',
        'organizzazione-e-gestione-interna' : 'Direzione e organizzazione interna',
        'affari-istituzionali-ed-europei' : 'Affari Istituzionali ed europei'
    }
    return settori[address];
};
const AreaListNew = () => {
    const [currentTile, setCurrentTile ] = useState(null);
    const [current, setCurrent ] = useState(null);
    const [subCurrent, setSubCurrent] = useState(null);
    const [openSubareas, setOpenSubareas] = useState(false);
    const [ sectors, setSectors ] = useState([]);
    
    const buildHtml = k => {
        const currentSector = setSector(k);
        const sector = sectors.filter( s => s.settore === currentSector )[0];
        let html = '<div class="area-direction">';
        if( sector?.image ) {
            html += `<img class="image-area-direction" src="${sector.image}" width="200" height="240" />`;
        }
        html += '<div style="div-area-direction">'
        if( sector?.carica ) {
            html += `<h3>${sector.carica}</h3>`;
        }
        if( sector?.nome ) {
            html += `<p>${sector.nome}</p>`;
        }
        if( sector?.staff) {
            html += `<div style="padding-top:15px;margin-top:15px;border-top:1px solid #eee">${sector.staff}</div>`;
        }
        html += '</div></div>'
        return html;
    }
     const setOpenArea = i => {
        if( sectors.length === 0) {
            return null;
        }
        setSubCurrent( state => state === i ? null : i )
    }
    const openTab = (i, element)=> {
        if( sectors.length === 0) return null;
        setCurrent( state => state === i ? null : i); setOpenSubareas(element.openSubAreas);
    }
    const openTile = (i)=> {
        if( sectors.length === 0) return null;
        setCurrentTile( state => state === i ? null : i);
    }
    useEffect(()=> {
        const getSectors = async ()=> {
            await axios
              .post("https://api.utilitalia.it/DMS/Documents/List", {
                "documentClasses": [
                "STRUTTURA"
            ],
            "heading": "\\CHI_SIAMO",
            "folder": "STRUTTURA",
            "documentValueDefs": [
                {
                    "code": "SETTORE"
                },
                {
                    "code": "NOME"
                },
                {
                    "code": "CARICA"
                },
                {
                    "code": "FOTO"
                },
                {
                    "code": "STAFF"
                },
                {
                    "code": "CONTATTI"
                }
            ],
            "pageStart": 1,
            "pageSize": 20,
                documentValueOrders: [
                  {
                    code: "POSIZIONE",
                    type: "3",
                    direction: "desc",
                  },
                ],
              })
              .then(async (response) => {
                const contatti = response?.data?.data?.documents;
                if (contatti) {
                    const object = await Promise.all(contatti.map(async doc => {
                  const values = await doc.values.reduce(async (accP, value) => {
                    const acc = await accP; // Aspetta il risultato del precedente accumulatore

                    if (value.code === 'SETTORE') acc.settore = value.value;
                    if (value.code === 'NOME') acc.nome = value.value;
                    if (value.code === 'CARICA') acc.carica = value.value;
                    if (value.code === 'STAFF') acc.staff = value.value;
                    if (value.code === 'FOTO' && value.value?.filePointer?.id) {
                      // Se c'è un campo FOTO, esegui la richiesta per ottenere l'immagine
                      let fotoResponse = await axios.post("https://api.utilitalia.it/DMS/Files/File", {
                        fileId: value.value?.filePointer?.id,
                      });

                      if (fotoResponse?.data?.data?.data) {
                        // Aggiungi la foto in formato base64
                        acc.image = "data:image/jpeg;base64," + fotoResponse.data.data.data;
                      }
                    }
                    return acc;
                  }, Promise.resolve({})); // Inizializza l'accumulatore come una promise vuota

                  return values;
                }));


                  setSectors(object);
                }
          });
          }
        if( sectors.length === 0) getSectors()
        return ()=> {
            setSectors([]);
        }
    }, [])
    return (
  <div className="list-categories-component">
   <div className="list-categories-component">
    {/*{window.location.pathname !== "/" && (
      <h6 className="pad-mobile">Servizi e Aree Correlate</h6>
    )}*/}

    <div className={`list-direction${openSubareas ? ' active':''}`} onClick={e => setOpenSubareas( state => !state ) } dangerouslySetInnerHTML={{__html:'Direzione Generale'}} />
    { openSubareas && <>
    <div className="expanded">
    <h2 style={{backgroundColor:'var(--blueColor)'}}><span dangerouslySetInnerHTML={{ __html: 'Direzione Generale' }} />
        <Link to="/direzione-generale/home">Approfondisci</Link>
        </h2>
        <div>
        <div dangerouslySetInnerHTML={{__html:buildHtml('direzione-generale')}} />
    </div>
    </div>
    <div className="list list--sub"  style={{marginTop: 20}}>
      {subareas.map((element, i) => (
        <Fragment key={i + 10}>
        { element?.empty ? (<div className="category" />) : (<div onClick={e => setOpenArea( i )} className={`category${ subCurrent === i ? ' active' :''}`} css={css`
                color: white;
                &:not(:hover):not(.active) {
                    background: var(--blueColor)
                }
                &:hover, &.active {
                  background: ${element.color}!important;
                }
                & svg *:not(:first-child) {
                    stroke: ${element.color}!important;
                }
              `}>
                  {element.icon}
                  <h4
                    dangerouslySetInnerHTML={{ __html: element.title }}
                  />
                </div>)}
        </Fragment>
      ))}
    </div>
    { subCurrent !== null && <div className="expanded expanded--sub">
        <h2 style={{backgroundColor:subareas[subCurrent].color}}><span dangerouslySetInnerHTML={{ __html: subareas[subCurrent].title }} />
        { subareas[subCurrent].to && subareas[subCurrent].to.indexOf('http') === -1 ? (<Link to={subareas[subCurrent].to}>Approfondisci</Link>) : (<span>Approfondisci</span>)}
        { subareas[subCurrent].to && subareas[subCurrent].to.indexOf('http') !== -1 && <a target="_blank" href={subareas[subCurrent].to}>Approfondisci</a> }
        </h2>
        <div>
        <div dangerouslySetInnerHTML={{__html:buildHtml(subareas[subCurrent].key)}} />
    </div></div>
    }</>}
  </div>
  <div className="tiles-div">
  <div className="tiles">
       {tiles.map((element, i) => (
              <div onClick={e => openTile(i)} key={i} to={element.href} className="tile">
                {element.icon}
                <h3>{element.title}</h3>
              </div>
            ))}
          </div>
          { currentTile !== null && <div className="expand"><h2 style={{background:tiles[currentTile].color}}><span dangerouslySetInnerHTML={{ __html: tiles[currentTile].title }} />{ tiles[currentTile].href && <Link to={tiles[currentTile].href}>Approfondisci</Link> }
        </h2><div dangerouslySetInnerHTML={{__html:buildHtml(tiles[currentTile].key)}}/></div>}
    </div>
   
    
    <div className="list">
      {areas.map((element, i) => (
        <Fragment key={i}>
        { element?.empty ? (<div className="category category--empty" style={{background:'transparent'}}/>) : (<div className={`category category-big${ current === i ? ' active' :''}`} onClick={e => {openTab( i, element)}} css={css`
                &:hover, &.active {
                  background: ${element.color}!important;
                }
                & svg *:not(:first-child) {
                    stroke: ${element.color}!important;
                }
              `}>
                  {element.icon}
                  <h4
                    style={{ fontSize: 18 }}
                    dangerouslySetInnerHTML={{ __html: element.title }}
                  />
                </div>)}
        </Fragment>
      ))}
    </div>
    { current !== null && <div className="expanded"><h2 style={{backgroundColor:areas[current].color}}><span dangerouslySetInnerHTML={{ __html: areas[current].title }} />
        <Link to={areas[current].to}>Approfondisci</Link>
        </h2>
        <div>
        <div dangerouslySetInnerHTML={{__html:buildHtml(areas[current].key)}} />
    </div></div>}
    
  </div>
)};

export default AreaListNew;
