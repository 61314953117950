import React, { Component } from "react";
import axios from "axios";
import "./ContattiPage.scss";
import IntroSection from "components/intro-section/IntroSection";
import { renderAttribute } from "functions/renderAttribute";
import Placeholders from "components/placeholders/Placeholders";
import { isMobile } from "react-device-detect";

const format = (title) => {
  let arr = [];
  let exludeWords = ["e", "di"];
  arr = title.split("-");
  return arr
    .map((word, i) => {
      return exludeWords.includes(word) && i !== 0
        ? [word]
        : word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const setSector = (address, subAddress) => {
    const settori = {
        'acqua' : 'Settore Acqua',
        'ambiente' : 'Settore Ambiente',
        'energia' : "Settore Energia",
        'area-affari-regolatori': 'Area Affari Regolatori',
        'amministrazione' : 'amministrazione',
        "area-strategie-pianificazione" : "Area Strategie e Pianificazione di settore",
        "area-giuridico-legislativa-fiscale":'Area Giuridico-Legislativa e Fiscale',
        'servizi-it-multimediali' : 'Servizi IT e Multimediali',
        'servizio-funerario' : 'Sefit Utilitalia – Servizi Funerari Italiani',
        'media-relations' : 'Media Relations',
        'eventi-convegni' : 'Eventi e Convegni',
        'direzione-generale' : 'DIREZIONE GENERALE',
        'organizzazione-e-gestione-interna' : 'Direzione e organizzazione interna',
        'area-strategie-pianificazione': 'Area Strategie e Pianificazione di settore',
        'area-lavoro-relazioni-industriali' : 'Area Lavoro e Relazioni Industriali',
        'affari-istituzionali-ed-europei' : 'Affari Istituzionali ed europei'
    }
  switch (address) {
    case "ambiente":
    case "energia":
    case "acqua":
    case "area-affari-regolatori":
    case "area-lavoro-relazioni-industriali":
    case "area-giuridico-legislativa-fiscale":
    case "area-comunicazione":
    case "amministrazione":
    case "area-strategie-pianificazione":
    case 'servizi-it-multimediali' : 
    case 'servizio-funerario' : 
    case 'media-relations' :
    case 'eventi-convegni' :
    case 'direzione-generale' :
    case 'organizzazione-e-gestione-interna' :
    case 'area-strategie-pianificazione':
    case 'area-lavoro-relazioni-industriali' :
    case 'affari-istituzionali-ed-europei' :
        return {
    "documentValueFilters": [
        {
            "code": "SETTORE",
            "start": settori[address]
        }
    ],
}
    default:
      return {
        heading: "\\CHI_SIAMO",
        folder: "CONTATTI",
      };
  }
};

export default class ContattiPage extends Component {
  state = {
    organico: [],
    contatti: [],
    loadingOrganico: true,
    loadingContatti: true,
  };

  async componentDidMount() {
    const sector = setSector(
      this.props.match.url.split("/")[1],
      this.props.match.url.split("/")[2]
    );
    //await this.fetchOrganico();
    await axios
      .post("https://api.utilitalia.it/DMS/Documents/List", {
        "documentClasses": [
        "STRUTTURA"
    ],
    "heading": "\\CHI_SIAMO",
    "folder": "STRUTTURA",
    "documentValueDefs": [
        {
            "code": "SETTORE"
        },
        {
            "code": "NOME"
        },
        {
            "code": "CARICA"
        },
        {
            "code": "FOTO"
        },
        {
            "code": "STAFF"
        },
        {
            "code": "CONTATTI"
        }
    ],
    "pageStart": 1,
    "pageSize": 20,
    documentValueFilters: sector.documentValueFilters,
        documentValueOrders: [
          {
            code: "POSIZIONE",
            type: "3",
            direction: "desc",
          },
        ],
      })
      .then((response) => {
        const contatti = response?.data?.data?.documents;
        if (contatti) {
          this.setState({
            contatti,
            loadingContatti: false,
          });
        }
      });
  }

  formatContatti = (contatti) => {
    const match = format(this.props.match.url.split("/")[1]);
    if (
      match === "Direzione Generale" ||
      match === "Pianificazione Strategia Sostenibilita"
    ) {
      return contatti.filter(
        (contatto) =>
          contatto.values
            .find((val) => val.code === "TITOLO")
            .value.toLowerCase() === "direzione generale"
      );
    } else if (match.toLowerCase() === "organizzazione e gestione interna") {
      return contatti.filter(
        (contatto) =>
          contatto.values
            .find((val) => val.code === "TITOLO")
            .value.toLowerCase() === "organizzazione e gestione interna"
      );
    }
    return contatti;
  };

  render() {
    return (
      <div id="contatti">
        <IntroSection
          category={format(this.props.match.url.split("/")[1])}
          subsector={format(this.props.match.url.split("/")[2])}
          subsubsector={
            this.props.match.url.split("/")[3] &&
            format(this.props.match.url.split("/")[3])
          }
        />
        <div className="container pad-mobile">
          <h2 className="general">
            I nostri <span>contatti</span>
          </h2>
          {this.state.loadingContatti  ? (
            <div className="contacts-list">
              <Placeholders number={2} type="contatto" />
            </div>
          ) : (
            <div className="contacts-list">
              {this.state.contatti.length > 0 && (
                <div className="organico">
                  {/* <h6>Organico</h6> */}
                  {this.state.contatti.map((element, i) => {
                    const texts = renderAttribute(element.values, "NOME");
                    return (
                      <React.Fragment key={`organico_${i}`}>
                        <h5
                          style={{ marginBottom: "1rem" }}
                          dangerouslySetInnerHTML={{
                            __html: renderAttribute(element.values, "CARICA"),
                          }}
                        />
                        <p
                        style={texts === "" ? { height: "0.5rem" } : {}}
                            dangerouslySetInnerHTML={{
                              __html: texts,
                            }}
                          />
                        <p
                            style={{marginTop:40}}
                            dangerouslySetInnerHTML={{
                              __html: renderAttribute(element.values, "STAFF"),
                            }}
                          />
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              {this.state.contatti.length > 0 && (
                <>
                  <div className="contatti">
                    {/* <h6>Contatti</h6> */}
                    {this.state.contatti.map(
                      (element, i) => (
                        <React.Fragment key={`contatti_${i}`}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: renderAttribute(element.values, "CONTATTI"),
                            }}
                          />
                          
                        </React.Fragment>
                      )
                    )}
                  </div>
                </>
              )}
             
              {!isMobile && this.state.contatti.length > 0 && (
                <>
                  <div className="left">
                    <div className="orange"></div>
                    <div className="blue"></div>
                    <div className="green"></div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
