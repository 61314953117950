import React, { Component, useState } from "react";
import moment from "moment";
import "moment/locale/it";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Home.scss";
import AreaListNew from "components/area-list/AreaListNew2";
import AreaListDirezione from "components/area-list/AreaListDirezione";
import CircularsList from "components/circulars-list/CircularsList";
import NavigationCircle from "components/navigation/circle/NavigationCircle";
import NavigationCircleRight from "components/navigation/circle/NavigationCircleRight";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Timer } from "icons/timer-bold.svg";
import { BsArrowRight as ArrowRight } from "react-icons/bs";
import axios from "axios";
import RenderIcon from "components/render-icon/RenderIcon";
import AttiDefault from "img/logo/simple-logo.jpg";
import { truncate } from "functions/formatTextFunctions";
import { Link } from "react-router-dom";
import Placeholders from "components/placeholders/Placeholders";
import Article from "components/article/Article";
import { isMobile } from "react-device-detect";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import ThemesList from "components/themes-list/ThemesList";

const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}

const ImgWithFallBack = (props) => {
  const [src, setSrc] = useState(props.src);
  return <img src={src} onError={() => setSrc(AttiDefault)} alt="" />;
};

const tiles = [
  {
    title: "Acqua",
    icon: <Water />,
    key: 'acqua',
    href: "/acqua/home",
    color: "linear-gradient(135deg, #0066a8 0%, #0a85bf 50%, #279fca 100%)",
    html: "<header><img src='/files/img/Tania_Tellini.jpeg' alt='Tania Tellini' width='60' height='60' /><div><h3>Direttore</h3><p>Tania Tellini</p></div></header><h3>Collaboratori</h3><p>Marco Gatta<br/>Elena Mauro<br/>Ada Polizzi</p>"
  },
  {
    title: "Ambiente",
    icon: <Leaf />,
    key: 'ambiente',
    href: "/ambiente/home",
    color: "linear-gradient(135deg, #398d99 0%, #36b29b 50%, #06c96d 100%)",
    html: "<header><img src='/files/img/Luca_Mariotto.jpeg' alt='Luca Mariotto' width='60' height='60' /><div><h3>Direttore</h3><p>Luca Mariotto</p></div></header><h3>Collaboratori</h3><p>Maria Carmina Glorioso<br/>Bernardo Piccioli Fioroni<br/>Riccardo Viselli</p>"
  },
  {
    title: "Energia",
    icon: <Thunder />,
    key: 'energia',
    href: "/energia/home",
    color: "linear-gradient(135deg, #ff8801 0%, #ffbd0d 50%, #eb650c 100%)",
    html: "<header><img src='/files/img/Mattia_Sica.jpeg' alt='Mattia Sica' width='60' height='60' /><div><h3>Direttore</h3><p>Mattia Sica</p></div></header><h3>Collaboratori</h3><p>Eleonora Capaccioli<br/>Francesco Vitolo</p>"
  },
];
const setSector = (address) => {
    const settori = {
        'acqua' : 'Settore Acqua',
        'ambiente' : 'Settore Ambiente',
        'energia' : "Settore Energia",
    }
    return settori[address];
};

// const Tiles = ()=> {
//     const [current, setCurrent] = useState( null );
//     const [ sectors, setSectors ] = useState([]);
    
//     const buildHtml = k => {
//         const currentSector = setSector(k);
//         const sector = sectors.filter( s => s.settore === currentSector )[0];
//         let html = '<div class="area-direction">';
//         if( sector?.image ) {
//             html += `<img class="image-area-direction" src="${sector.image}" width="200" height="240" />`;
//         }
//         html += '<div style="div-area-direction">'
//         if( sector?.carica ) {
//             html += `<h3>${sector.carica}</h3>`;
//         }
//         if( sector?.nome ) {
//             html += `<p>${sector.nome}</p>`;
//         }
//         if( sector?.staff) {
//             html += `<div style="padding-top:15px;margin-top:15px;border-top:1px solid #eee">${sector.staff}</div>`;
//         }
//         html += '</div></div>'
//         return html;
//     }
//     const openTab = (i)=> {
//         if( sectors.length === 0) return null;
//         setCurrent( state => state === i ? null : i);
//     }
//     useEffect(()=> {
//         const getSectors = async ()=> {
//             await axios
//               .post("https://api.utilitalia.it/DMS/Documents/List", {
//                 "documentClasses": [
//                 "STRUTTURA"
//             ],
//             "heading": "\\CHI_SIAMO",
//             "folder": "STRUTTURA",
//             "documentValueDefs": [
//                 {
//                     "code": "SETTORE"
//                 },
//                 {
//                     "code": "NOME"
//                 },
//                 {
//                     "code": "CARICA"
//                 },
//                 {
//                     "code": "FOTO"
//                 },
//                 {
//                     "code": "STAFF"
//                 },
//                 {
//                     "code": "CONTATTI"
//                 }
//             ],
//             "pageStart": 1,
//             "pageSize": 20,
//                 documentValueOrders: [
//                   {
//                     code: "POSIZIONE",
//                     type: "3",
//                     direction: "desc",
//                   },
//                 ],
//               })
//               .then(async (response) => {
//                 console.log(response?.data)
//                 return null;
//                 const data = typeof response?.data === "string" ? JSON.parse(response?.data) : response?.data;
//                 // Ora puoi accedere a data.data.documents senza problemi
//                 const contatti = data?.data?.documents;

//                 if (contatti) {
//                     const object = await Promise.all(contatti.map(async doc => {
//                     const values = await doc.values.reduce(async (accP, value) => {
//                     const acc = await accP; // Aspetta il risultato del precedente accumulatore

//                     if (value.code === 'SETTORE') acc.settore = value.value;
//                     if (value.code === 'NOME') acc.nome = value.value;
//                     if (value.code === 'CARICA') acc.carica = value.value;
//                     if (value.code === 'STAFF') acc.staff = value.value;
//                     if (value.code === 'FOTO' && value.value?.filePointer?.id) {
//                       // Se c'è un campo FOTO, esegui la richiesta per ottenere l'immagine
//                       let fotoResponse = await axios.post("https://api.utilitalia.it/DMS/Files/File", {
//                         fileId: value.value?.filePointer?.id,
//                       });

//                       if (fotoResponse?.data?.data?.data) {
//                         // Aggiungi la foto in formato base64
//                         acc.image = "data:image/jpeg;base64," + fotoResponse.data.data.data;
//                       }
//                     }
//                     return acc;
//                   }, Promise.resolve({})); // Inizializza l'accumulatore come una promise vuota

//                   return values;
//                 }));

//                   setSectors(object);
//                 }
//           });
//         }
//         if( sectors.length === 0) getSectors();
//         return ()=> {
//             setSectors([]);
//         }
//     }, []);
//     return (
//         <>
//          <div className="tiles">
//             {tiles.map((element, i) => (
//               <div onClick={e => openTab(i)} key={i} to={element.href} className="tile">
//                 {element.icon}
//                 <h3>{element.title}</h3>
//               </div>
//             ))}
//           </div>
//           { current !== null && <div className="expand"><h2 style={{background:tiles[current].color}}><span dangerouslySetInnerHTML={{ __html: tiles[current].title }} />{ tiles[current].href && <Link to={tiles[current].href}>Approfondisci</Link> }
//         </h2><div dangerouslySetInnerHTML={{__html:buildHtml(tiles[current].key)}}/></div>}
//         </>
//     )
//}

const colorEvent = (type) => {
  switch (type) {
    case "EVENTO INTERAMENTE UTILITALIA":
      return "#eb640c";
    case "EVENTI ESTERNI DA SEGNALARE":
      return "#00447b";
    case "EVENTO PATROCINATO O IN COLLABORAZIONE":
      return "#07ca6e";
    default:
      return "#00447b";
  }
};

function formatDate(date) {
  return moment(date).local("it").format("ll");
}

const formatUrl = (url) => {
  if (url.includes("eventi")) {
    return `/evento/${url.split("?").reverse()[0]}`;
  } else if (url.toLowerCase().includes("helpdesk_tool")) {
    return "/help-desk-tool";
  } else if (url.toLowerCase().includes("servizio_relazioni_esterne/ultime")) {
    return "/news-media/ultime-notizie";
  }
  return url;
};

const getRedirectUrl = (url) => {
  const base = url.split("https://").join("").split("http://").join("");
  const prefix = base.split(".");
  if (prefix[0] === "www") {
    if (prefix[1] === "utilitalia") {
      return formatUrl(url);
    }
  }
  return url;
};

const initialState = {
  banner: [],
  circolari: [],
  eventi: [],
  atti: [],
  utilities: [],
  news: [],
  newsFrom: 2,
  rassegna: [],
  rassegnaFrom: 1,
  eventMonth: parseInt(moment().format("M")),
  eventYear: parseInt(moment().format("YYYY")),
  carousel: 0,
  loadingAtti: true,
  loadingNew: true,
  loadingNews: true,
  loadingBanner: true,
  loadingUtilities: true,
  loadingRassegna: true,
  loadingCircolari: true,
  loadingEventi: true,
  //activeYear: parseInt(moment().format("YYYYMMDD")) < 20250228 ? parseInt(moment().format("YYYY")) - 1 : parseInt(moment().format("YYYY")),
  activeYear: parseInt(moment().format("YYYY")),
};

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export default class HomeNew2 extends Component {
  state = {
    ...initialState,
    sessionId: sessionStorage.getItem("sessionId"),
  };

  async componentDidMount() {
    this.setState(initialState);
    // banner
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 300 },
      };
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["CONTENUTO_WEB"],
          heading: "\\HOME",
          folder: "BANNER",
          documentValueDefs: [
            {
              code: "IMMAGINE",
            },
            {
              code: "LINK",
            },
            {
              code: "POSIZIONE",
            },
          ],
          pageSize: "0",
          pageStart: "1",
          documentValueOrders: [
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        },
        headers
      );
      const banner = response?.data?.data?.documents;
      this.setState({
        banner: banner || [],
      });
    } catch (err) {
      console.log("Error fetching banner CDM Home", err);
    }
    this.setState({ loadingBanner: false });

    // circolari
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 300 },
      };
      const response = await axios.post(
        "https://api.utilitalia.it/UTI/CIR/Circolari/List",
        {
          pageSize: "2",
          pageStart: "1",
          orderDirection: "desc",
        },
        headers
      );
      const circolari = response?.data?.data?.circolari;
      this.setState({
        circolari: circolari || [],
      });
    } catch (err) {
      console.log("Error fetching circolari CDM Home", err);
    }
    this.setState({ loadingCircolari: false });

    // pubblicazioni
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 7200 },
      };
      const response = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["PUBBLICAZIONI"],
          heading: "\\ATTIVITA\\PUBBLICAZIONI",
          maxRows: 2,
          documentValueOrders: [
            {
              code: "DATA",
              type: "5",
              direction: "desc",
            },
          ],
        },
        headers
      );
      const pubblicazioni = response?.data?.data?.documents;
      this.setState({
        pubblicazioni: pubblicazioni || [],
      });
    } catch (err) {
      console.log("Error fetching pubblicazioni CDM Home", err);
    }
    this.setState({ loadingAtti: false });

    // eventi
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 3600 },
      };
      const response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/List",
        {
          calendars: ["Calendario interno"],
          showNextDays: "365",
          showPastDays: "1",
          orderDirection: "asc",
          pageStart: "1",
          pageSize: "50",
        },
        headers
      );
      const eventi = response?.data?.data?.appointments;
      this.setState({
        eventi: eventi || [],
      });
    } catch (err) {
      console.log("Error fetching eventi CDM Home", err);
    }
    this.setState({ loadingEventi: false });

    // atti
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 7200 },
      };
      const response = await axios.post(
        "https://api.utilitalia.it/AGE/Events/List",
        {
          calendars: ["Calendario interno"],
          showNextDays: "0",
          showPastDays: "365",
          showOnlyWithTract: "true",
          maxRows: 2,
          orderDirection: "desc",
        },
        headers
      );
      const atti = response?.data?.data?.appointments;
      this.setState({
        atti: atti || [],
      });
    } catch (err) {
      console.log("Error fetching Atti CDM Home", err);
    }
    this.setState({ loadingAtti: false });

    // first-new
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 1800 },
      };
      let firstNew = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["NEWS"],
          heading: "\\COM\\NEWS\\ITALPRESS",
          //folder: this.state.activeYear,
          years: [],
          pageStart: 1,
          pageSize: 3,
          documentValueOrders: [
            {
              code: "DATA",
              type: "5",
              direction: "desc",
            },
          ],
        },
        headers
      );
      firstNew = firstNew?.data?.data?.documents;
      firstNew = await Promise.all(
          firstNew?.map(async (sing) => {
            let foto = await axios.post(
              "https://api.utilitalia.it/DMS/Files/File",
              {
                fileId: sing?.values?.find((value) => value.code === "ALLFOTO")
                  ?.value?.filePointer?.id,
              },
              headers
            );
            foto = foto?.data?.data;
            return { ...sing, ...foto };
          })
    );
      if (firstNew) {

      firstNew = firstNew[0];
        // let foto = `https://cms.utilitalia.it/dms/file/open/?${
        //   firstNew?.values?.find((value) => value.code === "ALLFOTO")?.value?.id
        // }`;

        this.setState({
          firstNew: {
            ...firstNew,
            //foto,
          },
        });
      }
    } catch (err) {
      console.log("Error fetching first news CDM Home", err);
    }
    this.setState({ loadingNew: false });

    // news
    try {
      const response = await this.fetchNews();
      const news = response?.data?.data?.documents;
      this.setState({
        news: news || [],
      });
    } catch (err) {
      console.log("Error fetching news CDM Home", err);
    }
    this.setState({ loadingNews: false });

    // stampa
    try {
      const response = await this.fetchRassegna();
      const rassegna = response?.data?.data?.documents;
      this.setState({
        rassegna: rassegna || [],
      });
    } catch (err) {
      console.log("Error fetching stampa CDM Home", err);
    }
    this.setState({ loadingRassegna: false });

    // utilities
    try {
      const headers = {
        headers: { "Maestrale-Cache-Control": 3600 },
      };
      let utilities = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["NEWS"],
          heading: "\\COM\\NEWS\\MONDOUTI",
          folder: this.state.activeYear,
          pageStart: "1",
          pageSize: "4",
          documentValueOrders: [
            {
              code: "DATA",
              type: "5",
              direction: "desc",
            },
          ],
        },
        headers
      );
      utilities = utilities?.data?.data?.documents;

      if (utilities) {
        utilities = utilities.map((element) => {
          return {
            ...element,
            foto:
              "https://cms.utilitalia.it/newsletter/le%20ultime/newsletter-" +
              element.values.find((value) => value.code === "CODICE").value +
              ".png",
          };
        });
        this.setState({
          utilities: utilities,
        });
      }
    } catch (err) {
      console.log("Error fetching utilities CDM Home", err);
    }
    this.setState({ loadingUtilities: false });
  }

  async componentDidUpdate(oldProps, oldState) {
    if (this.state.newsFrom !== oldState.newsFrom) {
      this.setState({ loadingNews: true });
      try {
        await this.fetchNews().then((response) => {
          const news = response?.data?.data?.documents;
          if (news && news.length === 0) {
            this.setState({ newsFrom: 1 });
          }
          this.setState({
            news: news || [],
          });
        });
      } catch (err) {
        console.log("Error fetching news CDU Home", err);
      }
      this.setState({ loadingNews: false });
    }
    if (this.state.eventMonth !== oldState.eventMonth) {
      this.setState({ loadingEventi: true });
      try {
        const response = await axios.post(
          "https://api.utilitalia.it/AGE/Events/List",
          {
            calendars: ["Calendario interno"],
            startDate: this.initialDate().startDate,
            endDate: this.initialDate().endDate,
            orderDirection: "asc",
            pageSize: "9",
            pageStart: "1",
          }
        );
        const eventi = response?.data?.data?.appointments;
        this.setState({ eventi: eventi || [] });
      } catch (err) {
        console.log("Error fetching eventi CDU Home", err);
      }
      this.setState({ loadingEventi: false });
    }
    if (this.state.rassegnaFrom !== oldState.rassegnaFrom) {
      try {
        this.setState({ loadingRassegna: true });
        const response = await this.fetchRassegna();
        const rassegna = response?.data?.data?.documents;
        if (rassegna && rassegna.length === 0) {
          this.setState({ rassegnaFrom: 1 });
        }
        this.setState({
          rassegna: rassegna || [],
        });
      } catch (err) {
        console.log("Error fetching rassegna CDU Home", err);
      }
      this.setState({ loadingRassegna: false });
    }
  }

  initialDate = () => {
    if (
      this.state.eventYear === parseInt(moment().format("YYYY")) &&
      this.state.eventMonth === parseInt(moment().format("M"))
    ) {
      return {
        startDate: moment().format("YYYY-MM-D"),
        endDate: moment().add(1, "M").format("YYYY-MM-D"),
      };
    } else {
      return {
        startDate: this.state.eventYear + "-" + this.state.eventMonth + "-1",
        endDate:
          this.state.eventYear +
          "-" +
          this.state.eventMonth +
          "-" +
          moment(this.state.eventMonth, "M").daysInMonth(),
      };
    }
  };

  fetchNews = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 300 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["NEWS"],
        headingPath: "\\COM\\NEWS",
        folder: this.state.activeYear,
        pageStart: this.state.newsFrom,
        pageSize: 3,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  fetchRassegna = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 300 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["RASSEGNA"],
        heading: "\\COM\\RS\\PREW",
        folder: moment().format("YYYY"),
        pageStart: this.state.rassegnaFrom,
        pageSize: 3,
        documentValueFilters: [
          {
            code: "AREA",
            value: "Utilitalia: si parla di Noi",
          },
        ],
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            // direction: "Asc",
            direction: "Desc",
          },
        ],
      },
      headers
    );
  };

  handleCarouselNavigation = (delta) => {
    if (delta < 0 && this.state.carousel === 0) {
      this.setState({ carousel: 4 });
    } else if (delta > 0 && this.state.carousel === 4) {
      this.setState({ carousel: 0 });
    } else {
      this.setState({
        carousel: this.state.carousel + delta,
      });
    }
  };

  handleNewsNavigation = (delta) => {
    this.setState({ newsFrom: this.state.newsFrom + delta * 3 });
  };

  handleCalendar = (delta) => {
    if (delta < 0 && this.state.eventMonth === 1) {
      this.setState({ eventMonth: 12, eventYear: this.state.eventYear - 1 });
    } else if (
      delta < 0 &&
      this.state.eventMonth === parseInt(moment().format("M")) &&
      this.state.eventYear === parseInt(moment().format("YYYY"))
    ) {
      this.setState({ eventMonth: parseInt(moment().format("M")) });
    } else if (delta > 0 && this.state.eventMonth === 12) {
      this.setState({ eventMonth: 1, eventYear: this.state.eventYear + 1 });
    } else {
      this.setState({
        eventMonth: this.state.eventMonth + delta,
      });
    }
  };

  isArrowBackAvailable = () =>
    !(
      this.state.eventMonth === parseInt(moment().format("M")) &&
      this.state.eventYear === parseInt(moment().format("YYYY"))
    );

  handleRassegnaNavigation = (delta) => {
    this.setState({ rassegnaFrom: this.state.rassegnaFrom + delta * 3 });
  };

  render() {
    // console.log("ATtI", this.state.atti);
    // console.log("Pubblicazioni", this.state.pubblicazioni);
    return (
      <div id="home">
        <Helmet>
          <title>Utilitalia - Federazione Utilities</title>
          <meta
            name="description"
            content="Utilitalia è la federazione che riunisce le Aziende speciali operanti nei servizi pubblici dell'Acqua, dell'Ambiente, dell'Energia Elettrica e del Gas."
          />
        </Helmet>

        <div className="container" style={{ padding: 0, paddingTop: "2rem" }}>
          <Swiper
            effect={"fade"}
            fadeEffect={{ crossFade: true }}
            speed={800}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            initialSlide={1}
            loop={true}
            navigation={{
              nextEl: ".next",
              prevEl: ".prev",
            }}
          >
            {this.state.loadingBanner ? (
              <div
                className="ph-item"
                style={{ padding: 0, border: 0, margin: 0 }}
              >
                <div
                  className="ph-col-12"
                  style={{ padding: 0, border: 0, margin: 0 }}
                >
                  <div
                    className="ph-picture"
                    style={{ height: isMobile ? 120 : 350 }}
                  ></div>
                </div>
              </div>
            ) : (
              (this.state.banner || [])
                // .sort((a, b) => {
                //   const findPosition = (arr) => {
                //     return arr.find((val) => val.code === "POSIZIONE").value;
                //   };
                //   return findPosition(a.values) < findPosition(b.values) ? -1 : 1;
                // })
                .map((element, i) => (
                  <SwiperSlide key={i}>
                    <a
                      // className="container"
                      href={getRedirectUrl(
                        element.values.find((value) => value.code === "LINK")
                          ? element.values.find(
                              (value) => value.code === "LINK"
                            ).value.url
                          : "#"
                      )}
                    >
                      <img
                        src={
                          "https://cms.utilitalia.it/" +
                          element.values.find(
                            (value) => value.code === "IMMAGINE"
                          ).value.url
                        }
                        // style={{ width: "50%" }}
                        alt=""
                      />
                    </a>
                  </SwiperSlide>
                ))
            )}
            <div className="controls">
              <NavigationCircleLeft swiper={true} color="#fff" />
              <NavigationCircleRight swiper={true} color="#fff" />
            </div>
          </Swiper>
        </div>
        <div className="container" style={{ paddingTop: "2rem" }}>
        
          <AreaListNew />
          {/* <ThemesList /> */}
          <div className="board pad-mobile">
            <div className="circolari">
              <h6>Circolari</h6>

              <CircularsList
                loading={this.state.loadingCircolari}
                circulars={this.state.circolari}
                col="2"
              />
            </div>
            <div className="calendario">
              <div className="heading">
                <h6>Calendario Eventi</h6>
                <Link to="/calendario-eventi">
                  <span className="general">Vedi tutti</span>
                  <ArrowRight />
                </Link>
              </div>
              <div className="calendario-eventi">
                <div className="heading" style={{ justifyContent: "center" }}>
                  {/* <ArrowLeft
                    color={!this.isArrowBackAvailable() ? "transparent" : "white"}
                    onClick={() => this.handleCalendar(-1)}
                  />
                  <span>
                    {moment(this.state.eventMonth, "M").format("MMMM")} {this.state.eventYear}
                  </span>
                  <ArrowRight
                    onClick={() => this.handleCalendar(1)} /> */}
                  <span>I prossimi appuntamenti</span>
                </div>
                <div className="events-list">
                  {this.state.loadingEventi ? (
                    <Placeholders number={2} type="evento-home" />
                  ) : this.state.eventi.length === 0 ? (
                    <div className="no-event">Non ci sono eventi</div>
                  ) : (
                    this.state.eventi.map((element, i) => {
                      let monthInfo = null;
                      if (
                        i === 0 ||
                        moment(element.startDate).month() !==
                          moment(this.state.eventi[i - 1].startDate).month()
                      ) {
                        monthInfo = (
                          <div
                            style={{
                              backgroundColor: "#aaa",
                              padding: "0.5rem 1rem",
                              margin: "0.5rem 0",
                              color: "white",
                              fontSize: 18,
                            }}
                          >
                            {`${
                              months[moment(element.startDate).month()]
                            } ${moment(element.startDate).year()}`}
                          </div>
                        );
                      }
                      const id = getId(element);
                      return (
                        <Fragment key={i}>
                          {!!monthInfo && monthInfo}
                          <Link
                            to={"/evento/" + id}
                            className="event"
                            key={i}
                          >
                            <div
                              className="date"
                              style={{ background: colorEvent(element.type) }}
                            >
                              <div>
                                {moment(element.startDate).format("DD")}
                              </div>
                              <div>
                                {moment(element.startDate).format("MMM")}
                              </div>
                            </div>
                            <div className="right-event">
                              <h4>{element.subject}</h4>
                              <div className="time">
                                <Timer />
                                <span>
                                  {moment(element.startDate).format("HH:mm")} -{" "}
                                  {moment(element.endDate).format("HH:mm")}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </Fragment>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="news pad-mobile">
          <div className="container">
            <h6>News</h6>
            <h2 className="general">
              Le ultime <span>notizie</span>
            </h2>
            <div className="row-news">
              {this.state.loadingNew || !this.state.firstNew ? (
                <Placeholders number={1} type="new-prima" />
              ) : (
                <div id="first-new" key="first-new" className="first-new">
                {this.state.firstNew?.data && (
                  <img
                    className="image"
                    src={
                      "data:image/jpeg;base64, " +
                      this.state.firstNew?.data
                    }
                    alt={
                        this.state.firstNew.values.find(
                          (value) => value.code === "TITOLO"
                        ).value
                      }
                  />
                )}
                  {/*<img src={this.state.firstNew.foto} alt="" />*/}
                  <div className="top">
                    <span className="date" style={{ marginBottom: 16 }}>
                      {moment(
                        this.state.firstNew.values.find(
                          (value) => value.code === "DATA"
                        ).value
                      ).format("ll")}
                    </span>
                    <h4 style={{ marginBottom: 0 }}>
                      {
                        this.state.firstNew.values.find(
                          (value) => value.code === "TITOLO"
                        ).value
                      }
                    </h4>
                    <p
                      style={{ paddingTop: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.firstNew.values.find(
                          (value) => value.code === "ABSTRACT"
                        ).value,
                      }}
                    />
                  </div>
                  <Link
                    to={"/notizia/" + this.state.firstNew.id}
                    className="btn small"
                  >
                    Leggi di più
                  </Link>
                </div>
              )}
              {/* <div className="list-news"> */}
              {this.state.loadingNews ? (
                <div
                  id="list-news-placeholder"
                  key="list-news-placeholder"
                  className="list-news"
                >
                  <Placeholders number={3} type="new" />
                </div>
              ) : (
                <div id="list-news" key="list-news" className="list-news">
                  <div className="top">
                    {this.state.news.map((element, i) => (
                      <div className="new" key={`news_${i}`}>
                        <div className="heading">
                          <div>
                            <RenderIcon
                              fetchDisabled
                              fonte={
                                element.values.find(
                                  (value) => value.code === "FONTE"
                                ).value
                              }
                            />
                          </div>
                          <span className="date">
                            {moment(
                              element.values.find(
                                (value) => value.code === "DATA"
                              ).value
                            ).format("ll")}
                          </span>
                          <span className="general">NEWS</span>
                        </div>
                        <h4>
                          {truncate(
                            element.values.find(
                              (value) => value.code === "TITOLO"
                            ).value,
                            100
                          )}
                        </h4>
                        <Link
                          to={"/notizia/" + getId(element)}
                          className="btn small"
                        >
                          Leggi di più
                        </Link>
                      </div>
                    ))}
                  </div>
                  <NavigationCircle
                    margin={0}
                    function={this.handleNewsNavigation}
                    color="var(--greyColor)"
                    disabled={this.state.newsFrom === 1}
                  />
                </div>
              )}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/*       <div className="rassegna-section container">
          <h2 className="general pad-mobile">
            Parlano di <span>noi</span>
          </h2>
          <div className="rassegna">
            <h6 className="pad-mobile">Rassegna Stampa</h6>
            <div className="right">
              <div className="rassegna-list pad-mobile">
                {this.state.loadingRassegna ? (
                  <Placeholders number={3} type="rassegna" />
                ) : (
                  this.state.rassegna.map((article, i) => (
                    <Article article={article} key={i} />
                  ))
                )}
              </div>
              <NavigationCircle
                margin={0}
                function={this.handleRassegnaNavigation}
                color="var(--greyDarkColor)"
                disabled={this.state.rassegnaFrom === 1}
              />
            </div>
          </div>
        </div> */}
        <div className="iniziative container pad-mobile">
          <div className="row">
            <div className="utilities">
              <h2 className="general" style={{ textAlign: "left" }}>
                Mondo <span>Utilities</span>
              </h2>
              <div className="list-utilities">
                {this.state.loadingUtilities ? (
                  <Placeholders number={4} type="utilities" />
                ) : (
                  this.state.utilities.slice(0, 4).map((element, i) => (
                    <div className="utility" key={i}>
                      <div
                        style={{
                          paddingBottom: 8,
                          fontSize: "0.8rem",
                          textAlign: "right",
                        }}
                      >
                        <span className="date">
                          {moment(
                            element.values.find(
                              (value) => value.code === "DATA"
                            ).value
                          ).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div style={{ paddingBottom: 8 }}>
                        <div className="module" style={{ height: "2.8rem" }}>
                          <h4 className="fade">
                            {
                              element.values.find(
                                (value) => value.code === "TITOLO"
                              ).value
                            }
                          </h4>
                        </div>
                      </div>
                      <div style={{ paddingBottom: 16 }}>
                        <div className="module" style={{ height: "4.2rem" }}>
                          <div
                            className="fade"
                            dangerouslySetInnerHTML={{
                              __html: clearHtml(
                                element.values.find(
                                  (value) => value.code === "THTML"
                                ).value
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          src={element.foto}
                          alt=""
                          style={{
                            maxHeight: 55,
                            maxWidth: "50%",
                            objectFit: "contain",
                          }}
                        />
                        <Link to={"/notizia/" + getId(element)}>
                          <NavigationCircleRight />
                        </Link>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          {/* <h2 className="general">
            Le nostre <span>iniziative</span>
          </h2> */}
          <div className="row" style={{ alignItems: "flex-end" }}>
            <div className="atti">
              <h2 className="general" style={{ textAlign: "left" }}>
                Atti e Pubblicazioni
              </h2>
              <div className="atti-list">
                {this.state.atti.length === 0 ? (
                  <Placeholders number={2} type="atto" />
                ) : (
                  <div className="atto">
                    <div className="content">
                      <ImgWithFallBack
                        src={
                          "https://cms.utilitalia.it/Immagini/Atti/" +
                          this.state.atti[0].tag +
                          ".jpg"
                        }
                      />

                      <div className="title">
                        <p
                          style={{
                            textTransform: "uppercase",
                            color: "var(--greyDarkestColor",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {formatDate(this.state.atti[0].startDate)}
                        </p>
                        <h5>{this.state.atti[0].subject}</h5>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link
                            to={"/atto/" + this.state.atti[0].id}
                            className="btn small"
                          >
                            Leggi di più
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="lineH"></div>
                  </div>
                )}

                {this.state.pubblicazioni?.length > 0 && (
                  <div className="atto" style={{ paddingTop: 24 }}>
                    <div className="content">
                      <ImgWithFallBack
                        src={"/Immagini/Atti/Area Ambiente.jpg"}
                      />

                      <div className="title">
                        <p
                          style={{
                            textTransform: "uppercase",
                            color: "var(--greyDarkestColor",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {formatDate(
                            this.state.pubblicazioni[0].values[0].value
                          )}
                        </p>
                        <h5>{this.state.pubblicazioni[0].values[1].value}</h5>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link
                            to={
                              "pubblicazioni/list/list/" +
                              getId(this.state.pubblicazioni[0])
                            }
                            className="btn small"
                          >
                            Leggi di più
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="atti">
              {/*       <h2 className="general" style={{ textAlign: "left" }}>
                Atti e Pubblicazioni
              </h2> */}
              <div className="atti-list">
                {this.state.atti.length === 0 ? (
                  <Placeholders number={2} type="atto" />
                ) : (
                  <div className="atto">
                    <div className="content">
                      <ImgWithFallBack
                        src={
                          "https://cms.utilitalia.it/Immagini/Atti/" +
                          this.state.atti[1].tag +
                          ".jpg"
                        }
                      />

                      <div className="title">
                        <p
                          style={{
                            textTransform: "uppercase",
                            color: "var(--greyDarkestColor",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {formatDate(this.state.atti[1].startDate)}
                        </p>
                        <h5>{this.state.atti[1].subject}</h5>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link
                            to={"/atto/" + getId(this.state.atti[1])}
                            className="btn small"
                          >
                            Leggi di più
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="lineH"></div>
                  </div>
                )}

                {this.state.pubblicazioni?.length > 0 && (
                  <div className="atto" style={{ paddingTop: 24 }}>
                    <div className="content">
                      <ImgWithFallBack
                        src={"/Immagini/Atti/Area Ambiente.jpg"}
                      />

                      <div className="title">
                        <p
                          style={{
                            textTransform: "uppercase",
                            color: "var(--greyDarkestColor",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          {formatDate(
                            this.state.pubblicazioni[1].values[0].value
                          )}
                        </p>
                        <h5>{this.state.pubblicazioni[1].values[1].value}</h5>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Link
                            to={
                              "pubblicazioni/list/list/" +
                              getId(this.state.pubblicazioni[1])
                            }
                            className="btn small"
                          >
                            Leggi di più
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const clearHtml = (text) => {
  return text
    .split(/<[^>]*>?/gm)
    .join("")
    .split("&nbsp;")
    .join("");
};
