import axios from "axios";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import imgAcqua from "img/settori-aree/acqua.jpg";
import imgAmbiente from "img/settori-aree/ambiente.jpg";
import imgEnergia from "img/settori-aree/energia.jpg";
import imgAmministrazione from "img/settori-aree/amministrazione.jpg";
import imgAmministrazioneNew from "img/settori-aree/amministrazione-page.jpg";
import imgGiuridico from "img/settori-aree/legislativa.jpg";
import imgLavoro from "img/settori-aree/lavoro.jpg";
import imgRegolazione from "img/settori-aree/affari-regolatori.jpg";
import imgComunicazione from "img/settori-aree/comunicazione.jpg";
import imgStrategie from "img/settori-aree/strategie.jpg";
import imgEventi from "img/settori-aree/eventi-convegni.jpg";
import imgMediaRelations from "img/settori-aree/media-relations.jpg";
import imgSefit from "img/settori-aree/immagine-sefit.jpg";
import imgIT from "img/settori-aree/servizi-it-multimedia.jpg";
import imgIstituzionali from "img/settori-aree/affari-istituzionali.jpg";

export function renderCategory(category) {
  switch (category) {
    case "/acqua/home":
    case "/acqua/home/": {
      return {
        name: "acqua",
        image: imgAcqua,
        icon: <Water />,
        gradient: "acquaGradient",
        color: "acqua",
        sector: true,
        motto: true,
        subcategory: false,
        title: "Utilitalia - Acqua",
        description:
          "Utilitalia rappresenta la quasi totalità degli operatori dei servizi idrici in Italia. Visita il nostro sito per avere maggiori informazioni",
        features: [
          {
            title: "Associa gli operatori",
            text: "Utilitalia associa gli operatori dei servizi Idrici, dall’acquedotto alla depurazione: captazione, adduzione, potabilizzazione, distribuzione, fognatura, collettamento e infine depurazione dei reflui. Associa gli Enti e le strutture che a diverso titolo pianificano e progettano i sistemi idrici su scala locale.",
          },
          {
            title: "Rappresenta gli operatori",
            text: "Utilitalia rappresenta la quasi totalità degli operatori dei servizi idrici in Italia.",
          },
          {
            title: "Supporta le imprese associate",
            text: "Offre supporto alle associate sulle questioni normative, regolatorie, tariffarie e di sviluppo tecnologico e nella predisposizione di analisi, piani economici e finanziari.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/acqua/circolari",
          },
          {
            icon: "intersect",
            title: "Studi e <span>ricerche</span>",
            text: "All'interno della sezione gli studi dell'Area",
            to: "/acqua/studi-e-ricerche/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "acqua@utilitalia.it",
            tel: "06 945282.30",
            color: "var(--acqua)",
            to: "/acqua/contatti",
          },
        ],
      };
    }
    case "/ambiente/home":
    case "/ambiente/home/": {
      return {
        name: "ambiente",
        image: imgAmbiente,
        icon: <Leaf />,
        gradient: "ambienteGradient",
        color: "ambiente",
        motto: true,
        sector: true,
        subcategory: false,
        title: "Utilitalia - Ambiente",
        description:
          "Rappresenta le imprese dei servizi pubblici di igiene ambientale presso autorità europee, nazionali e regionali, le Commissioni parlamentari e i Ministeri competenti.",
        features: [
          {
            title: "Rappresenta le imprese",
            text: "Rappresenta le imprese dei servizi pubblici di igiene ambientale presso autorità europee, nazionali e regionali, le Commissioni parlamentari e i Ministeri competenti.",
          },
          {
            title: "Realizza studi",
            text: "Realizza studi di carattere tecnico, scientifico, economico e finanziario sui molteplici aspetti del ciclo di gestione dei rifiuti urbani.",
          },
          {
            title: "Assistenza",
            text: "Informa le imprese associate sulle evoluzioni e le novità legislative e normative di settore.",
          },
          {
            title: "Informa le imprese associate",
            text: "Offre assistenza tecnico amministrativa sui vari aspetti del ciclo integrato dei rifiuti urbani: pulizia e decoro urbano, raccolta differenziata, autorizzazione e gestione impianti, applicazione degli accordi e normativa di settore e promuove la corretta informazione e la sensibilizzazione di tutti i soggetti responsabili a diverso titolo della produzione e della gestione dei rifiuti urbani.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/ambiente/circolari",
          },
          // {
          //   icon: "question",
          //   title: "Tutti i <span>quesiti</span>",
          //   text: "Tutte le risposte ai quesiti posti dalle nostre associate",
          //   to: "/ambiente/quesiti",
          // },
          {
            icon: "intersect",
            title: "Studi e <span>ricerche</span>",
            text: "All'interno della sezione gli studi dell'Area",
            to: "/ambiente/studi-e-ricerche/list",
          },
          // {
          //   icon: "file-text",
          //   title: "CSR e <span>sostenibilita</span>",
          //   text: "La sezione del sito di Utilitalia dedicata alla csr e alla Sostenibilita",
          //   to: "/ambiente/csr-sostenibilita/home",
          // },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "ambiente@utilitalia.it",
            tel: "06 945282.90",
            //fax: "06 945282.00",
            color: "var(--ambiente)",
            to: "/ambiente/contatti",
          },
        ],
      };
    }
    // case "/ambiente/csr-sostenibilita/home":
    // case "/ambiente/csr-sostenibilita/home/": {
    //   return {
    //     name: "ambiente",
    //     image: imgAmbiente,
    //     icon: <Leaf />,
    //     gradient: "ambienteGradient",
    //     color: "ambiente",
    //     subcategory: "csr <span>e</span> sostenibilita",
    //     sector: true,
    //     features: [
    //       {
    //         title: "Rappresenta le imprese",
    //         text: "Benvenuti nella sezione del sito di Utilitalia dedicata alla CSR e alla Sostenibilita, temi ormai strategici per tutte le Associate.",
    //       },
    //       {
    //         title: "Realizza studi",
    //         text: "L’azione di Utilitalia è finalizzata ad incoraggiare, affiancare e promuovere presso le Associate l’attività di rendicontazione non finanziaria, attraverso percorsi informativi, formativi e di condivisione di buone pratiche.",
    //       },
    //     ],
    //     boxes: [
    //       {
    //         icon: "file",
    //         title: "Misurarsi per <span>migliorare</span>",
    //         text: "All'interno della sezione tutte le circolari dell'Area",
    //         to: "/ambiente/csr-sostenibilita/misurarsi-per-migliorare/category-home",
    //       },
    //       {
    //         icon: "line",
    //         title: "Bilanci di <span>sostenibilita</span>",
    //         text: "In questa sezione sono raccolti per annualità i Bilanci di Sostenibilita delle Utility associate.",
    //         to: "/ambiente/csr-sostenibilita/bilanci-sostenibilita/list",
    //       },
    //       {
    //         icon: "question",
    //         title: "Attività del <span>GDL</span>",
    //         text: "Nella sezione sono raccolti i verbali delle riunioni del gruppo di lavoro, i principali documenti di interesse e tutte le comunicazioni riguardanti il gruppo di lavoro. ",
    //         to: "/ambiente/csr-sostenibilita/attivita-gdl/category-home",
    //       },
    //       {
    //         icon: "intersect",
    //         title: "Studi e <span>documentazione</span>",
    //         text: "All'interno della sezione gli studi e le ricerche dell'Area.",
    //         to: "/ambiente/csr-sostenibilita/studi-e-documentazione/list",
    //       },
    //       {
    //         icon: "file-text",
    //         title: "Convegni e <span>seminari</span>",
    //         text: "Utilitalia, in collaborazione con il GdL “Csr e Sostenibilita”, ha realizzato appuntamenti convegnistici e formativi sui temi di principale interesse.",
    //         to: "/ambiente/csr-sostenibilita/convegni-e-seminari/list",
    //       },
    //       {
    //         icon: "envelope",
    //         title: "I nostri <span>contatti</span>",
    //         email: "ambiente@utilitalia.it",
    //         tel: "06 945282.90",
    //         fax: "06 945282.00",
    //         color: "var(--ambiente)",
    //         to: "/ambiente/csr-sostenibilita/contatti",
    //       },
    //     ],
    //   };
    // }
    case "/energia/home":
    case "/energia/home/": {
      return {
        name: "energia",
        image: imgEnergia,
        icon: <Thunder />,
        gradient: "energiaGradient",
        color: "energia",
        motto: true,
        sector: true,
        subcategory: false,
        title: "Utilitalia - Energia",
        description:
          "Utilitalia favorisce tutti i processi di innovazione tecnologica e di processo e supporta l’attività delle Associate nella prospettiva dello sviluppo dei servizi smart e delle smart grids",
        features: [
          {
            title: "Rappresenta le imprese",
            text: "L'impegno dell'Area Energia è volto al confronto con le Istituzioni per promuovere - attraverso un quadro normativo e regolatorio coerente e certo - il rispetto delle dinamiche concorrenziali, lo sviluppo del sistema energetico nazionale e la sostenibilita ambientale, come definita negli impegni europei e internazionali assunti dal nostro Paese.",
          },
          {
            title: "Sfrutta il potenziale",
            text: "La Federazione si adopera inoltre perché venga sfruttato pienamente il potenziale offerto dall’efficienza energetica, capace di combinare sostenibilita ambientale, innovazione tecnologica e risparmio.",
          },
          {
            title: "Favorisce i processi di innovazione tecnologica",
            text: "Utilitalia favorisce tutti i processi di innovazione tecnologica e di processo e supporta l’attività delle Associate nella prospettiva dello sviluppo dei servizi smart e delle smart grids, attraverso la promozione di sperimentazione di applicazioni tecnologicamente avanzate.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/energia/circolari",
          },
          {
            icon: "intersect",
            title: "Portale <span>Ambiti</span>",
            text: "<strong style=\"color:red;text-transform:uppercase\">Servizio temporaneamente sospeso</strong><br/>Fornisce agli Associati della Federazione notizie e aggiornamenti utili sugli ambiti di gara relativi al settore della distribuzione del gas. Una piattaforma semplice e intuitiva che permette di ottenere una fotografia del contesto normativo e competitivo nazionale.",
            to: "/energia/portale-ambiti/page",
          },
          {
            icon: "file-text",
            title: "Bollettino <span>finanziamenti</span>",
            text: "Focus mensile dei programmi e delle scadenze dei programmi di finanziamento agevolato per le Imprese",
            to: "/energia/bollettino-finanziamenti/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "reti.energia@utilitalia.it",
            tel: "06 945282.40",
            color: "var(--energia)",
            to: "/energia/contatti",
          },
        ],
      };
    }
    case "/area-affari-regolatori/home":
    case "/area-affari-regolatori/home/": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Settings />,
        gradient: "affari",
        color: "affari",
        subcategory: false,
        title: "Utilitalia - Affari Regolatori",
        description:
          "L'Area Affari Regolatori nasce sul finire del 2017 per creare un presidio organizzativo. Visita il sito e scopri di più",
        features: [
          {
            title: "Origine",
            text: "L'Area Affari Regolatori nasce sul finire del 2017 per creare un presidio organizzativo trasversale sulle tematiche relative all’attività di regolamentazione delle infrastrutture e dei mercati posta in capo ad ARERA (Autorità di Regolazione per Energia Reti e Ambiente).",
          },
          {
            title: "Scopo",
            text: "L'Area si occupa di monitorare, valutare e analizzare l'evoluzione degli aspetti di regolazione economico-tariffaria, e della qualità tecnica e commerciale dei servizi regolati nei settori dell'energia elettrica, del gas, dei servizi idrici e ambientali.",
          },
          {
            title: "Attività",
            text: "L'attività dell'Area consente quindi di raccogliere e coordinare le posizioni dei propri Associati, guidando i processi di cambiamento promossi dalla regolazione di settore, valutandone l’impatto sul sistema associativo e rafforzando il ruolo di rappresentanza di Utilitalia presso gli stakeholder nazionali e comunitari.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-affari-regolatori/circolari",
          },
          {
            icon: "file-text",
            title: "Norme e <span>regole</span>",
            text: "Sezione dedicata alla gestione delle tematiche regolatorie...",
            to: "/area-affari-regolatori/norme-e-regole/home",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "regolatorio@utilitalia.it",
            tel: "06 945282.50",
            color: "var(--affari)",
            to: "/area-affari-regolatori/contatti",
          },
        ],
      };
    }
    case "/area-affari-regolatori/norme-e-regole/home":
    case "/area-affari-regolatori/norme-e-regole/home/": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Settings />,
        gradient: "affari",
        color: "affari",
        subcategory: "Norme e Regole",
        features: [
          {
            text: " Gestione delle tematiche regolatorie, in grado di raccogliere e coordinare le posizioni dei propri associati rispetto alle questioni ed alle proposte introdotte dal Regolatore, consentirà pertanto di rafforzare il ruolo di rappresentanza di Utilitalia nei confronti di alcuni stakeholder istituzionali, rispetto ad un'area tematica trasversale al proprio tessuto associativo. ",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Antitrust",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'Antitrust",
            to: "/area-affari-regolatori/norme-e-regole/antitrust/list",
          },
          {
            icon: "file",
            title: "UE",
            text: "All'interno della sezione tutte le tematiche regolatorie dell' Unione Europea",
            to: "/area-affari-regolatori/norme-e-regole/ue/list",
          },
          {
            icon: "file",
            title: "Acqua",
            text: "All'interno della sezione tutte le tematiche regolatorie sull'acqua",
            to: "/area-affari-regolatori/norme-e-regole/acqua/list",
          },
          {
            icon: "file",
            title: "Energia",
            text: "All'interno della sezione tutte le tematiche regolatorie inerenti l'energia",
            to: "/area-affari-regolatori/norme-e-regole/energia/list",
          },
          {
            icon: "file",
            title: "Ambiente",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'ambiente",
            to: "/area-affari-regolatori/norme-e-regole/ambiente/list",
          },
        ],
      };
    }
    case "/area-lavoro-relazioni-industriali/home":
    case "/area-lavoro-relazioni-industriali/home/": {
      return {
        name: "lavoro <span>e</span> relazioni industriali",
        image: imgLavoro,
        icon: <Bag />,
        gradient: "lavoro",
        color: "lavoro",
        subcategory: false,
        title: "Utilitalia - Lavoro e Relazioni Industriali",
        description:
          "Nel settore energetico, ambientale e idrico, Utilitalia è un punto di riferimento per quanto concerne i contratti collettivi nazionali di lavoro e i rapporti con le Organizzazioni sindacali",
        features: [
          {
            title: "Rappresentanza",
            text: "Rappresenta le imprese del settore energetico, ambientale e idrico nella sottoscrizione dei contratti collettivi nazionali di lavoro, nei rapporti con le Organizzazioni sindacali di settore e con gli Enti competenti in materia sindacale e del lavoro (Istituzioni previdenziali, Commissioni Parlamentari, Ministero del Lavoro, Commissione di Garanzia e altre Autorità nazionali e territoriali).",
          },
          {
            title: "Assistenza",
            text: "Supporta le imprese nell’applicazione ed interpretazione dei CCNL sottoscritti, informa sull’evoluzione  e sulle novità legislative e giurisprudenziali in materia giuslavoristica, fornendo il proprio contributo alla soluzione dei principali problemi attuativi ed interpretativi.",
          },
          // {
          //   title: "CCNL SETTORE GAS-ACQUA",
          //   text: "Il CCNL del settore Gas-Acqua, che Utilitalia sottoscrive insieme alle associazioni settoriali di Confindustria, è applicato a circa 47.000 lavoratori complessivi, di cui oltre 41.000 dipendenti delle imprese associate.",
          // },
          // {
          //   title: "CCNL SETTORE ELETTRICO",
          //   text: "Utilitalia è firmataria - con l’Associazione settoriale di Confindustria e con Enel, Terna, Sogin e GSE - anche del CCNL del settore Elettrico, applicato a poco più di 52.600 addetti complessivi, di cui circa 13.900 dipendenti delle imprese associate.",
          // },
          // {
          //   title: "CCNL SERVIZI AMBIENTALI",
          //   text: "Utilitalia è infine titolare del CCNL dei servizi ambientali, applicato a circa 47.000 lavoratori delle imprese associate.",
          // },
        ],
        moreFeatures: [
          {
            title: "CCNL SETTORE GAS-ACQUA",
            text: "Il CCNL del settore Gas-Acqua, che Utilitalia sottoscrive insieme alle associazioni settoriali di Confindustria, è applicato a circa 48.000 lavoratori complessivi, di cui quasi 42.000 dipendenti delle imprese associate.",
          },
          {
            title: "CCNL SETTORE ELETTRICO",
            text: "Utilitalia è firmataria - con l’Associazione settoriale di Confindustria e con Enel, Terna, Sogin e GSE - del CCNL del settore Elettrico, applicato a poco più di 53.000 addetti complessivi, di cui 14.700 dipendenti delle imprese associate.",
          },
          {
            title: "CCNL SERVIZI AMBIENTALI",
            text: "Utilitalia è titolare del CCNL di settore dei servizi ambientali, applicato a oltre 50.000 lavoratori delle imprese associate e sottoscritto con Confindustria-Cisambiente, Lega Coop Produzione e Servizi, Confcooperative, A.G.C.I e Assoambiente.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-lavoro-relazioni-industriali/circolari",
          },
          {
            icon: "file-text",
            title: "Dati <span>Statistici</span>",
            text: "I dati elaborati trattano argomenti quali il costo del lavoro e l’occupazione nel settore.",
            to: "/area-lavoro-relazioni-industriali/dati-statistici/list",
          },
          {
            icon: "file-text",
            title: "Quesiti",
            text: "Tutte le risposte ai quesiti posti dalle nostre associate",
            to: "/area-lavoro-relazioni-industriali/quesiti",
          },
          {
            icon: "file-text",
            title: "Normativa",
            text: "Segnalazioni delle ultime novità normative e delle recenti pronunce giurisprudenziali",
            to: "/area-lavoro-relazioni-industriali/normativa/list",
          },
          {
            icon: "file-text",
            title: "Giurisprudenza",
            text: "Segnalazioni delle ultime novità normative e delle recenti pronunce giurisprudenziali",
            to: "/area-lavoro-relazioni-industriali/giurisprudenza/list",
          },
          {
            icon: "file-text",
            title: "Contratti <span>Collettivi</span>",
            text: "La Federazione è firmataria del CCNL del settore Gas-Acqua, del CCNL del settore Elettrico, del CCNL dei servizi ambientali e del CCNL per i dipendenti delle imprese pubbliche del settore funerario.",
            to: "/area-lavoro-relazioni-industriali/contratti/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "lavoro@utilitalia.it",
            tel: "06 945282.60",
            color: "var(--lavoro)",
            to: "/area-lavoro-relazioni-industriali/contatti",
          },
        ],
      };
    }
    case "/area-giuridico-legislativa-fiscale/home":
    case "/area-giuridico-legislativa-fiscale/home/": {
      return {
        name: "giuridico legislativa fiscale",
        image: imgGiuridico,
        icon: <Steam />,
        gradient: "giuridico",
        color: "giuridico",
        subcategory: false,
        title: "Utilitalia - Giuridico Legislativo Fiscale",
        description:
          "Utilitalia svolge per le proprie associate un ampio ed attento monitoraggio delle maggiori novità normative e giurisprudenziali in materia di servizi di interesse economico generale a rete (SIEG)",
        features: [
          {
            title: "Monitoraggio",
            text: "Utilitalia svolge per le proprie associate un ampio ed attento monitoraggio delle maggiori novità normative e giurisprudenziali in materia di servizi di interesse economico generale a rete (SIEG), fornendo il necessario supporto alla risoluzione delle conseguenti problematiche interpretative ed attuative. In particolare, effettua un costante approfondimento della disciplina inerente l’organizzazione dei servizi (modalità di affidamento, governance, vincoli retributivi, etc.), assicurando un valido ausilio alle associate.",
          },
          {
            title: "Assistenza",
            text: "L'area, cui fa capo il Gruppo Tecnico Trasversale Giuridico-legislativo e Fiscale (GTT Giuridico), offre assistenza alle associate anche sui temi inerenti la disciplina degli appalti e delle concessioni, nonché quella fiscale e tributaria.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-giuridico-legislativa-fiscale/circolari",
          },
          {
            icon: "file-text",
            title: "Fiscale e <span>Tributario</span>",
            text: "Nella sezione sono raccolte le circolari fiscali e tributarie, nonché i principali documenti di interesse",
            to: "/area-giuridico-legislativa-fiscale/fiscale-e-tributario",
          },
          // {
          //   icon: "file-text",
          //   title: "Dal <span>Parlamento</span>",
          //   text: "All’interno le notizie relative all’iter di approvazione dei principali provvedimenti di interesse trasversale per il mondo delle utility.",
          //   to: "/area-giuridico-legislativa-fiscale/dal-parlamento/list",
          // },
          {
            icon: "file-text",
            title: "Appalti e <span>Concessioni</span>",
            text: "Nella sezione sono raccolte le circolari in materia di appalti e concessioni, nonché i principali documenti di interesse.",
            to: "/area-giuridico-legislativa-fiscale/appalti-e-concessioni/category-home",
          },
          {
            icon: "file-text",
            title: "Norme e <span>Regole</span>",
            text: "Una raccolta dei principali documenti di interesse: provvedimenti normativi, pronunce della magistratura, altri atti di istituzioni o Autorità.",
            to: "/area-giuridico-legislativa-fiscale/norme-e-regole/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "giuridico.legislativo@utilitalia.it",
            tel: "06 945282.90.10",
            //fax: "06 94528200",
            color: "var(--giuridico)",
            to: "/area-giuridico-legislativa-fiscale/contatti",
          },
        ],
      };
    }
    case "/affari-istituzionali-ed-europei/home":
    case "/affari-istituzionali-ed-europei/home/": {
      return {
        name: "Affari istituzionali ed europei",
        image: imgIstituzionali,
        icon: <Steam />,
        gradient: "affari",
        color: "affari",
        subcategory: false,
        title: "Utilitalia - Affari istituzionali ed europei",
        description:
          "Utilitalia promuove gli interessi delle imprese associate presso le istituzioni nazionali ed eurounitarie, anche attraverso l’organizzazione di incontri e di momenti di confronto con i membri del Parlamento italiano e del Parlamento europeo, nonché con i rappresentanti del Governo nazionale, della Commissione UE, del Consiglio europeo e di ogni altro stakeholder rilevante",
        features: [
          {
            title: "Rappresentanza",
            text: "Utilitalia promuove gli interessi delle imprese associate presso le istituzioni nazionali ed eurounitarie, anche attraverso l’organizzazione di incontri e di momenti di confronto con i membri del Parlamento italiano e del Parlamento europeo, nonché con i rappresentanti del Governo nazionale, della Commissione UE, del Consiglio europeo e di ogni altro stakeholder rilevante.",
          },
          {
            title: "Lobbying",
            text: "Grazie alla partecipazione alle consultazioni pubbliche e alle audizioni periodicamente organizzate dalle istituzioni nazionali ed europee, nonché all’attività di engagement informale, la Federazione cerca di intervenire nel processo legislativo al fine di garantire l’efficacia, l’efficienza e l’economicità dei servizi forniti ai cittadini dalle proprie associate.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "<span>Documenti e posizionamenti</span>",
            //text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/affari-istituzionali-ed-europei/documenti",
          },
          {
            icon: "file-text",
            title: "<span>Norme e regole UE</span>",
            text: "Una raccolta dei principali documenti di interesse: provvedimenti normativi, pronunce della magistratura, altri atti di istituzioni o Autorità.",
            //to: "/area-giuridico-legislativa-fiscale/norme-e-regole/ue/list",
            to: "/affari-istituzionali-ed-europei/norme-e-regole/ue/list"
          },
          // {
          //   icon: "file-text",
          //   title: "Dal <span>Parlamento</span>",
          //   text: "All’interno le notizie relative all’iter di approvazione dei principali provvedimenti di interesse trasversale per il mondo delle utility.",
          //   to: "/area-giuridico-legislativa-fiscale/dal-parlamento/list",
          // },
          {
            icon: "file-text",
            title: "<span>Ultime dall’Europa</span>",
            text: "Il mensile Utilitalia con le principali notizie dalle istituzioni e dalle associazioni Europee. Position Paper, notizie, informazioni parlamentari e legislative dell’ultimo mese. Le informazioni protette da password sono riservate agli associati.",
            to: "/ultime-da-europa/",
          },
          // {
          //   icon: "file-text",
          //   title: "Norme e <span>Regole</span>",
          //   text: "Una raccolta dei principali documenti di interesse: provvedimenti normativi, pronunce della magistratura, altri atti di istituzioni o Autorità.",
          //   to: "/area-giuridico-legislativa-fiscale/norme-e-regole/list",
          // },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.90.10",
            //fax: "06 94528200",
            color: "var(--giuridico)",
            to: "/affari-istituzionali-ed-europei/contatti",
          },
        ],
      };
    }
    case "/area-comunicazione/home":
    case "/area-comunicazione/home/": {
      return {
        name: "comunicazione",
        image: imgComunicazione,
        icon: <Chat />,
        gradient: "comunicazione",
        color: "comunicazione",
        subcategory: false,
        title: "Utilitalia - Comunicazione",
        description:
          "L'Area Comunicazione si occupa della promozione di Utilitalia. Sviluppa progetti per il raggiungimento degli obiettivi associativi e per la realizzazione di iniziative nazionali",
        features: [
          {
            title: "Promozione",
            text: `L'Area Comunicazione si occupa della promozione di Utilitalia e dell'intero comparto associativo presso i diversi interlocutori esterni, contribuendo alla definizione di messaggi e strumenti. Sviluppa progetti per il raggiungimento degli obiettivi associativi e per la realizzazione di iniziative nazionali. Tra le iniziative nazionali periodiche promosse Utilitalia, il Festival dell’Acqua (www.festivalacqua.org) ed Ecomondo (www.ecomondo.com) appuntamento internazionale di riferimento per la green e circular economy.`,
          },
          {
            title: "Relazioni",
            text: "L'Area Comunicazione gestisce le relazioni esterne con le principali testate giornalistiche nazionali e locali, le concessionarie pubblicitarie, le Università e i centri di ricerca, nell'ambito della realizzazione di servizi giornalistici, campagne di sensibilizzazione, presentazioni di ricerche o di eventi divulgativi.",
          },
          {
            title: "Supporto",
            text: `Supporta le Aree e i Settori della federazione nella progettazione e realizzazione di eventi convegnistici e seminariali. Ha ideato e promosso “Utili all'Italia”, primo censimento delle migliori pratiche dei servizi pubblici, con i progetti realizzati delle imprese. Ha ideato e gestisce ULTIME, il "quotidiano dei servizi pubblici" dedicato ad acqua, ambiente ed energia. Ha ideato e gestisce i FOCUS ON, collana di approfondimenti su temi monografici interessanti per il mondo dei servizi pubblici locali. Raccoglie atti di convegni promossi dalla Federazione.
            Si occupa infine di promuovere le attività, gli eventi e le iniziative della Federazione e di tutte le aziende associate, attraverso strategie, strumenti di comunicazione e social network (Twitter, LinkedIn, Instagram, Telegram).`,
          },
        ],
        boxes: [
          {
            icon: "file-text",
            title: "Progetti di <span>Comunicazione</span>",
            // text: "All'interno l'archivio di tutti gli eventi",
            to: "/area-comunicazione/progetti-di-comunicazione",
          },
          {
            icon: "file-text",
            title: "Gallery <span>Eventi</span>",
            text: "All'interno l'archivio di tutti gli eventi",
            to: "/calendario-eventi",
          },
          {
            icon: "file-text",
            title: "Press <span>Kit</span>",
            text: "All'interno i comunicati stampa, media kit e video",
            to: "/news-media/press-kit",
          },
          {
            icon: "file-text",
            title: "Rassegna <span>Stampa</span>",
            text: "La Rassegna Stampa quotidiana in collaborazione con P.Review.",
            to: "/news-media/rassegna-stampa",
          },
          {
            icon: "file-text",
            title: "<span>Ultime</span>",
            text: "Il quotidiano di Utilitalia. Circolari della federazione, notizie giornalistiche, informazioni parlamentari e legislative delle ultime 24 ore. ",
            to: "/ultime",
          },
          {
            icon: "file-text",
            title: "<span>Pubblicazioni</span>",
            text: `La collana di approfondimenti proposta da UTILITALIA su temi
            monografici interessanti per il mondo dei servizi pubblici locali.`,
            to: "/pubblicazioni/list",
          },
          {
            name: "youtube",
            icon: "file-text",
            title: "Youtube <span>Utilitalia</span>",
            text: "Il canale youtube dedicato al Mondo Utilitalia",
            //to: "https://www.youtube.com/user/FederutilityStampa",
            to: "https://www.youtube.com/@YoutubeUtilitalia/featured",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "areacomunicazione@utilitalia.it",
            color: "var(--comunicazione)",
            to: "/area-comunicazione/contatti",
          },
          // {
          //   icon: "file-text",
          //   title: "Focus <span>On</span>",
          //   text: `Focus On è la collana di approfondimenti proposta da UTILITALIA su temi
          //   monografici interessanti per il mondo dei servizi pubblici locali.`,
          //   to: "/area-comunicazione/focus-on/list",
          // },
        ],
        // banners: [
        //   { img: imgYoutility, url: "https://www.educazionedigitale.it/youtilities/" },
        //   { img: imgUtiliAllItalia, url: "http://censimento.utilitalia.it/" },
        //   { img: imgRubinetto, url: "http://eventi.utilitalia.it/campagna_rubinetto.html" },
        // ],
        // banners: fetchBanners,
      };
    }
    case "/eventi-convegni/home":
    case "/eventi-convegni/home/": {
      return {
        name: "Eventi e Convegni",
        image: imgEventi,
        icon: <Chat />,
        gradient: "comunicazione",
        color: "comunicazione",
        subcategory: false,
        title: "Utilitalia - Eventi e Covnegni",
        description:
          "La funzione Eventi e Convegni si occupa dell’ideazione e della relativa promozione ed organizzazione di iniziative della Federazione contribuendo alla definizione di messaggi e strumenti attraverso eventi su scala nazionale e convegni volti ad approfondire gli argomenti di interesse per l’intero comparto associativo",
        features: [
          {
            title: "Promozione e<br/>Organizzazione",
            text: `La funzione <strong>Eventi e Convegni</strong> si occupa dell’ideazione e della relativa promozione ed organizzazione di iniziative della Federazione contribuendo alla definizione di messaggi e strumenti attraverso eventi su scala nazionale e convegni volti ad approfondire gli argomenti di interesse per l’intero comparto associativo. Raccoglie gli atti ed i contributi di convegni e seminari promossi dalla Federazione.`,
          },
          {
            title: "Supporto",
            text: "Supporta i Settori e le Aree nella progettazione e realizzazione di eventi convegnistici e seminariali per facilitare lo scambio di conoscenze e favorire la discussione di temi di interesse per i settori di riferimento rispondendo alle esigenze delle Aziende associate.",
          },
          {
            title: "Partnership",
            text: `Per alcune iniziative di interesse nazionale che la Federazione organizza con cadenza annuale e biennale (ad. esempio Festival dell’Acqua ed Innovation Day), sono previste collaborazioni che prevedono un’adesione economica in qualità di partner sostenitore.<br/>Possono aderire con questa modalità sia le aziende associate ad Utilitalia che le aziende esterne al sistema che abbiano caratteristiche attinenti ai progetti.`,
          },
          {
            title: "Patrocini",
            text: `La concessione del patrocinio non oneroso da parte di Utilitalia rappresenta una forma di adesione ed apprezzamento ad iniziative ritenute meritevoli nei settori di interesse per l’Associazione, che annoverino tra i relatori un rappresentante di Utilitalia e che non confliggano con attività già condotte dalla Federazione sui medesimi temi.
<ul><li>Uso del logo: il richiedente potrà utilizzare il logo di Utilitalia esclusivamente per la promozione dell’iniziativa patrocinata, nei materiali di comunicazione, previa approvazione grafica.
</li><li>Revoca del Patrocinio: Utilitalia si riserva il diritto di revocare il patrocinio qualora emergano elementi che compromettono l’immagine dell’associazione o se l’evento risulta non conforme ai criteri dichiarati nella richiesta.
</li></ul>`,
          },
        ],
        boxes: [
          {
            icon: "file-text",
            title: "Iniziative e <span>progetti</span>",
            // text: "All'interno l'archivio di tutti gli eventi",
            //to: "/eventi-convegni-comunicazione/progetti-di-comunicazione",
            to: "/eventi-convegni/iniziative-progetti/home",
          },
          {
            icon: "file-text",
            title: "<span>Eventi</span>",
            text: "All'interno l'archivio di tutti gli eventi",
            to: "/calendario-eventi",
          },
          {
            icon: "file-text",
            title: "Atti e <span>Pubblicazioni</span>",
            text: `Raccolta degli atti presentati nel corso degli eventi e degli approfondimenti elaborati dalla Federazione sui temi monografici di interesse per il mondo dei servizi pubblici locali.`,
            to: "/atti",
          },
          {
            icon: "file-text",
            title: "Web e <span>Grafica</span>",
            text: "Supporto per la realizzazione dei contenuti grafici e multimediali relativi agli eventi e alle pubblicazioni della Federazione e gestione dell’aggiornamento del sito web.",
            //to: "https://www.youtube.com/user/FederutilityStampa",
            //to: "https://www.youtube.com/@YoutubeUtilitalia/featured",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "eventi@utilitalia.it",
            color: "var(--comunicazione)",
            tel: "Tel: 06 945282.76",
            to: "/eventi-convegni/contatti",
          },
          // {
          //   icon: "file-text",
          //   title: "Focus <span>On</span>",
          //   text: `Focus On è la collana di approfondimenti proposta da UTILITALIA su temi
          //   monografici interessanti per il mondo dei servizi pubblici locali.`,
          //   to: "/area-comunicazione/focus-on/list",
          // },
        ],
        // banners: [
        //   { img: imgYoutility, url: "https://www.educazionedigitale.it/youtilities/" },
        //   { img: imgUtiliAllItalia, url: "http://censimento.utilitalia.it/" },
        //   { img: imgRubinetto, url: "http://eventi.utilitalia.it/campagna_rubinetto.html" },
        // ],
        // banners: fetchBanners,
      };
    }
    case "/eventi-convegni/iniziative-progetti/home":
    case "/eventi-convegni/iniziative-progetti/home/": {
      return {
        name: "Eventi e Convegni",
        image: imgEventi,
        icon: <Chat />,
        gradient: "comunicazione",
        color: "comunicazione",
        subcategory: 'Iniziative e progetti',
        title: "Utilitalia - Eventi e Convegni - Iniziative e progetti",
        disableWhatWeDo: true,
        description:
          "La funzione Eventi e Convegni si occupa dell’ideazione e della relativa promozione ed organizzazione di iniziative della Federazione contribuendo alla definizione di messaggi e strumenti attraverso eventi su scala nazionale e convegni volti ad approfondire gli argomenti di interesse per l’intero comparto associativo",
        boxes: [
            {
                onlyTitle: true,
                title: 'Iniziative'
            },
          {
            icon: "file-text",
            title: "<span>Festival dell'acqua</span>",
            text: "Ideato e promosso dalla Federazione, è uno fra i principali appuntamenti nazionali sui temi del servizio idrico.",
            //to: "/eventi-convegni-comunicazione/progetti-di-comunicazione",
            to: "https://festivalacqua.org",
          },
          {
            icon: "file-text",
            title: "<span>Innovation</span>",
            text: "Le utilities sono protagoniste attive della trasformazione sostenibile e digitale: per cogliere a pieno le opportunità di questa sfida e favorire la diffusione delle progettualità innovative delle associate, Utilitalia ha ideato il progetto Utilitalia Innovation.",
            to: "https://www.utilitaliainnovation.it",
          },
          {
            icon: "file-text",
            title: "<span>Ecomondo</span>",
            text: `Appuntamento internazionale di riferimento per la green, water e circular economy, che vede ormai da anni la consolidata partecipazione di Utilitalia con lo stand dedicato “Piazza delle Utilities”.`,
            to: "https://www.ecomondo.com",
          },
          {
                onlyTitle: true,
                title: 'Progetti'
            },
          {
            icon: "file-text",
            title: "<span>Utili all'Italia</span>",
            text: "Primo censimento delle migliori pratiche dei servizi pubblici, con i progetti realizzati delle imprese.",
            //to: "https://www.youtube.com/user/FederutilityStampa",
            to: "https://censimento.utilitalia.it",
          },
          {
            icon: "file",
            title: "<span>Galleria servizi pubblici</span>",
            text: "Immagini, momenti, emozioni e lavoro, il lavoro con il quale 98 mila persone delle aziende associate ad Utilitalia assicurano servizi pubblici ai cittadini italiani tutti i giorni, tutto il giorno. Questa Galleria di immagini, si alimenta con le fotografie che le aziende continueranno a caricare a beneficio dei cittadini, dei giornalisti, degli studenti.",
            to: "https://gallery.utilitalia.it/default.aspx",
          },
          // {
          //   icon: "file-text",
          //   title: "Focus <span>On</span>",
          //   text: `Focus On è la collana di approfondimenti proposta da UTILITALIA su temi
          //   monografici interessanti per il mondo dei servizi pubblici locali.`,
          //   to: "/area-comunicazione/focus-on/list",
          // },
        ],
        // banners: [
        //   { img: imgYoutility, url: "https://www.educazionedigitale.it/youtilities/" },
        //   { img: imgUtiliAllItalia, url: "http://censimento.utilitalia.it/" },
        //   { img: imgRubinetto, url: "http://eventi.utilitalia.it/campagna_rubinetto.html" },
        // ],
        // banners: fetchBanners,
      };
    }
    case "/media-relations/home":
    case "/media-relations/home/": {
      return {
        name: "Media Relations",
        image: imgMediaRelations,
        icon: <Chat />,
        gradient: "strategie",
        color: "strategie",
        subcategory: false,
        title: "Utilitalia - Media Relations",
        description:
          "La funzione Media e Relations si occupa dell’ideazione e della relativa promozione ed organizzazione di iniziative della Federazione contribuendo alla definizione di messaggi e strumenti attraverso eventi su scala nazionale e convegni volti ad approfondire gli argomenti di interesse per l’intero comparto associativo",
        features: [
          {
            title: "Rapporti con i Media",
            text: `La funzione Media Relations gestisce i rapporti tra la Federazione e i principali organi di informazione nazionali e internazionali: quotidiani, web, radio e tv. Predispone comunicati stampa relativi alle iniziative federali e predispone gli interventi dei vertici all’interno di trasmissioni televisive e radiofoniche. Opera in rapporto costante con gli uffici comunicazione delle aziende associate per concordare iniziative comuni, con l’obiettivo di amplificare i contenuti divulgati.`,
          },
          {
            title: "Digital",
            text: "Pianificazione della comunicazione online dell’azienda. Gestione dei canali social della Federazione – Linkedin, X, Instagram e YouTube – attraverso aggiornamenti quotidiani sulle principali iniziative federali e sugli interventi dei vertici in occasione di eventi pubblici. Opera in rapporto costante con gli uffici comunicazione delle aziende associate per rilanciare, a livello nazionale, le iniziative delle utilities sui territori.",
          },
          {
            title: "Ultime",
            text: `Gestisce la newsletter Ultime, il quotidiano di Utilitalia che contiene le circolari della Federazione, notizie giornalistiche, iniziative delle associate e informazioni parlamentari e legislative delle ultime 24 ore.`,
          },
        ],
        boxes: [
            {
                onlyTitle: true,
                title: 'Iniziative e Progetti'
            },
          {
            icon: "file-text",
            title: "<span>Green Dream</span>",
            text: "Green Dream è un viaggio nell’Italia dell’acqua, dell’energia e dell’ambiente. Un’avventura in diverse tappe in cui due viaggiatori si trasformano in lavoratori delle utilities.",
            //to: "/eventi-convegni-comunicazione/progetti-di-comunicazione",
            to: "https://www.green-dream.it/",
          },
          {
            icon: "file-text",
            title: "<span>Incontri con i leader politici</span>",
            text: "In occasione delle elezioni politiche del 2022 e delle elezioni europee del 2024, la Federazione ha organizzato un ciclo di incontri con i leader dei diversi schieramenti per entrare nel merito delle proposte e delle soluzioni dedicate ai temi dell’acqua, dell’energia e dell’ambiente.",
            to: "https://www.youtube.com/watch?v=PeXSrZVswGk&list=PLWQnPIrQeZH7n3dcQK7h2jhDIQOHDKt8W",
          },
          {
            icon: "file-text",
            title: "<span>Quasar</span>",
            text: `Quasar è il programma di Rai 2 che analizza i grandi temi di attualità in chiave scientifica.`,
            to: "https://www.raiplay.it/video/2024/12/Quasar---Puntata-del-07122024-e2fd88dd-455d-41c8-b2f5-efefd589048b.html",
          },
          {
            icon: "file-text",
            title: "<span>Comunicati Stampa</span>",
            text: `Comunicati stampa della Federazione.`,
            to: "/news-media/press-kit/comunicati-stampa/",
          },
          {
                empty: true,
            },
          {
            icon: "file-text",
            title: "Canali <span>Social</span>",
            socialList: true,
            //text: "Supporto per la realizzazione dei contenuti grafici e multimediali relativi agli eventi e alle pubblicazioni della Federazione e gestione dell’aggiornamento del sito web.",
            //to: "https://www.youtube.com/user/FederutilityStampa",
            //to: "https://www.youtube.com/@YoutubeUtilitalia/featured",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: 'stampa@utilitalia.it',
            tel: '06 945282.78',
            color: "var(--comunicazione)",
            to: "/media-relations/contatti",
          },
          // {
          //   icon: "file-text",
          //   title: "Focus <span>On</span>",
          //   text: `Focus On è la collana di approfondimenti proposta da UTILITALIA su temi
          //   monografici interessanti per il mondo dei servizi pubblici locali.`,
          //   to: "/area-comunicazione/focus-on/list",
          // },
        ],
        // banners: [
        //   { img: imgYoutility, url: "https://www.educazionedigitale.it/youtilities/" },
        //   { img: imgUtiliAllItalia, url: "http://censimento.utilitalia.it/" },
        //   { img: imgRubinetto, url: "http://eventi.utilitalia.it/campagna_rubinetto.html" },
        // ],
        // banners: fetchBanners,
      };
    }
    case "/direzione-generale/home":
    case "/direzione-generale/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "blueColor",
        color: "blueColor",
        subcategory: false,
        sector: false,
        title: "Utilitalia - Direzione generale",
        description: "",
        disableWhatWeDo: true,
        features: [
          // {
          //   title: "Attività 1",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
          // {
          //   title: "Attività 2",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
          // {
          //   title: "Attività 3",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
          // {
          //   title: "Attività 4",
          //   text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          // },
        ],
        boxes: [
          // {
          //   icon: "file-text",
          //   title: "<span>Amministrazione</span>",
          //   // text: `L’Amministrazione si occupa della contabilità e del bilancio di Utilitalia, di Utilitalia Servizi S.r.l e della Confservizi.`,
          //   to: "/direzione-generale/amministrazione/home",
          // },
          {
            icon: "file-text",
            title: "<span>Organizzazione</span> e gestione interna",
            // text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            to: "/direzione-generale/organizzazione-e-gestione-interna/home",
          },
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/direzione-generale/circolari",
          },
          /*      {
            icon: "file-text",
            title: "Pianificazione, Strategia, Sostenibilità",
            // text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            to: "/direzione-generale/pianificazione-strategia-sostenibilita/home",
          }, */
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            //fax: "06 945282.00",
            color: "var(--comunicazione)",
            to: "/direzione-generale/contatti",
          },
          // {
          //   icon: "file-text",
          //   title: "Notizie <span>Antitrust</span>",
          //   text: `Nella sezione le news antitrust in collaborazione con lo Studio Legale "Rucellai&Raffaelli"`,
          //   to: "/direzione-generale/notizie-antitrust/list",
          // },
          {
            icon: "file-text",
            title: "CSR e <span>sostenibilita</span>",
            text: "La sezione del sito di Utilitalia dedicata alla csr e alla Sostenibilita",
            to: "/direzione-generale/csr-sostenibilita/home",
          },
        ],
      };
    }
    case "/direzione-generale/organizzazione-e-gestione-interna/home":
    case "/direzione-generale/organizzazione-e-gestione-interna/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "blueColor",
        color: "blueColor",
        sector: false,
        disableWhatWeDo: false,
        subcategory: "Organizzazione e gestione interna",
        title:
          "Utilitalia - Direzione generale - Organizzazione e gestione interna",
        description:
          "L'area Organizzazione e gestione interna si occupa di lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        features: [
          {
            title: "Presidenza e Direzione",
            text: `Le attività di <strong>Presidenza e Direzione</strong> comprendono il coordinamento strategico e operativo per garantire la rappresentanza istituzionale. la collaborazione con enti nazionali ed europei ed un’efficace dialogo con le associate. Rappresentano inoltre il punto nevralgico per il raccordo interno delle attività, favorendo un'azione sinergica e integrata tra le diverse aree e settori.`,
          },
          {
            title: "Coordinamento Organi Statutari",
            text: `Il <strong>Coordinamento degli Organi Statutari</strong> rappresenta un’attività fondamentale per garantire il funzionamento efficace e ordinato delle strutture decisionali dell’organizzazione. Questo coinvolge la gestione degli Organi in senso stretto, come l’<strong>Assemblea Generale</strong>, la <strong>Giunta Esecutiva</strong> e i <strong>Consigli Direttivi</strong> Acqua, Ambiente, Energia, nonché dei Comitati e delle Commissioni di pertinenza della Direzione Generale.`,
          },
          {
            title: "Organizzazione Interna",
            text: `L’<strong>Organizzazione Interna</strong> svolge un ruolo di supporto tecnico e amministrativo nelle attività operative e organizzative della Federazione. Si occupa della gestione del personale Utilitalia e cura i rapporti con i fornitori.<br/>Inoltre, è responsabile della gestione tecnica degli uffici, nonché dell’implementazione e del monitoraggio delle normative relative alla <strong>Sicurezza sul Lavoro</strong> per il personale Utilitalia e alla Privacy. Coordina, infine, lo Staff di Segreteria, dedicato al supporto operativo delle attività della struttura federale.`,
          },
          {
            title: "MY.UTILITALIA.IT",
            text: `E’ il portale dedicato alle associate per esprimere la propria rappresentanza all'interno degli Organismi tecnici di Utilitalia e per la gestione delle credenziali di accesso al sito web utilitalia.it.`,
          },
        ],
        boxes: [
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            //fax: "06 94528200",
            color: "var(--comunicazione)",
            to: "/organizzazione-e-gestione-interna/contatti",
          },
        ],
      };
    }
    case "/direzione-generale/pianificazione-strategia-sostenibilita/home":
    case "/direzione-generale/pianificazione-strategia-sostenibilita/home/": {
      return {
        name: "Direzione generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        sector: false,
        disableWhatWeDo: false,
        subcategory: "Pianificazione, Strategia, Sostenibilità",
        title:
          "Utilitalia - Direzione generale - Pianificazione, Strategia, Sostenibilità",
        description:
          "L'area Organizzazione e gestione interna si occupa di lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        features: [
          {
            title: "Attività 1",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
          {
            title: "Attività 2",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
          {
            title: "Attività 3",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
          {
            title: "Attività 4",
            text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          },
        ],
        boxes: [
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            fax: "06 945282.00",
            color: "var(--comunicazione)",
            to: "/pianificazione-strategia-sostenibilita/contatti",
          },
        ],
      };
    }
    case "/direzione-generale/csr-sostenibilita/home":
    case "/direzione-generale/csr-sostenibilita/home/": {
      return {
        name: "Direzione Generale",
        image: imgAmministrazione,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        subcategory: "csr <span>e</span> sostenibilita",
        sector: true,
        disableWhatWeDo: false,
        features: [
          {
            title: "Rappresenta le imprese",
            text: "Benvenuti nella sezione del sito di Utilitalia dedicata alla CSR e alla Sostenibilita, temi ormai strategici per tutte le Associate.",
          },
          {
            title: "Realizza studi",
            text: "L’azione di Utilitalia è finalizzata ad incoraggiare, affiancare e promuovere presso le Associate l’attività di rendicontazione non finanziaria, attraverso percorsi informativi, formativi e di condivisione di buone pratiche.",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Misurarsi per <span>migliorare</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/category-home",
          },
          {
            icon: "line",
            title: "Bilanci di <span>sostenibilita</span>",
            text: "In questa sezione sono raccolti per annualità i Bilanci di Sostenibilita delle Utility associate.",
            to: "/direzione-generale/csr-sostenibilita/bilanci-sostenibilita/list",
          },
          {
            icon: "question",
            title: "Attività del <span>GDL</span>",
            text: "Nella sezione sono raccolti i verbali delle riunioni del gruppo di lavoro, i principali documenti di interesse e tutte le comunicazioni riguardanti il gruppo di lavoro. ",
            to: "/direzione-generale/csr-sostenibilita/attivita-gdl/category-home",
          },
          {
            icon: "intersect",
            title: "Studi e <span>documentazione</span>",
            text: "All'interno della sezione gli studi e le ricerche dell'Area.",
            to: "/direzione-generale/csr-sostenibilita/studi-e-documentazione/list",
          },
          {
            icon: "file-text",
            title: "Convegni e <span>seminari</span>",
            text: "Utilitalia, in collaborazione con il GdL “Csr e Sostenibilita”, ha realizzato appuntamenti convegnistici e formativi sui temi di principale interesse.",
            to: "/direzione-generale/csr-sostenibilita/convegni-e-seminari/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            tel: "06 945282.10",
            fax: "06 945282.00",
            color: "var(--comunicazione)",
            to: "/direzione-generale/csr-sostenibilita/contatti",
          },
        ],
      };
    }
    case "/direzione-generale/amministrazione/home":
    case "/direzione-generale/amministrazione/home/":
    case "/amministrazione/home":
    case "/amministrazione/home/": {
      return {
        name: "Amministrazione",
        image: imgAmministrazioneNew,
        icon: <Book />,
        gradient: "amministrazione",
        color: "amministrazione",
        sector: false,
        disableWhatWeDo: false,
        title: "Utilitalia - Amministrazione",
        description:
          "L’Amministrazione si occupa della contabilità e del bilancio di Utilitalia, di Utilitalia Servizi S.r.l e della Confservizi.",
        features: [
          {
            title: "Contabilità",
            text: `L’Amministrazione si occupa della contabilità e del bilancio di Utilitalia, di Utilitalia Servizi S.r.l e della Confservizi.`,
          },
          {
            title: "Adesione",
            text: `E’ preposta alla determinazione ed esazione dei contributi associativi e risponde ai quesiti delle imprese in materia di calcolo delle quote di adesione. Cura gli aspetti legati all’adesione dei nuovi associati predisponendo per la Giunta Esecutiva la formalizzazione delle richieste di adesione.`,
          },
          {
            title: "Gestione",
            text: `Si occupa anche della gestione amministrativa degli Organi federali e del personale, supportando le attività del Collegio dei Revisori e della Società di revisione per la certificazione del bilancio. Si interfaccia e supporta, adottando il controllo di gestione, le attività delle Aree e Servizi Federali. Cura anche la gestione amministrativa dei permessi sindacali retribuiti riferiti al CCNL Servizi Ambientali.`,
          },
        ],
        boxes: [
          {
            icon: "file-text",
            title: "Associate",
            text: `Sezione deI soggetti associati ad Utilitalia, forniscono acqua attualmente a circa l’80% della popolazione, distribuiscono gas ad oltre il 30% degli abitanti ed energia elettrica a circa il 15 % della popolazione italiana e offrono servizi ambientali a circa
            il 55% degli abitanti.dicata alla gestione delle tematiche regolatorie...`,
            to: "/associate",
          },
          {
            icon: "file",
            title: "Come <span>aderire</span>",
            text: "Per aderire alla Federazione dovrà essere compilata e inviata a mezzo e-mail amministrazione@utilitalia.it la scheda rilevamento dati...",
            to: "/amministrazione/come-aderire/page",
          },
          {
            icon: "file",
            title: "Modulistica",
            to: "/amministrazione/modulistica/page",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "amministrazione@utilitalia.it",
            tel: "06 945282.20",
            color: "var(--comunicazione)",
            to: "/amministrazione/contatti",
          },
        ],
      };
    }
    case "/area-affari-regolatori/norme-e-regole":
    case "/area-affari-regolatori/norme-e-regole/": {
      return {
        name: "affari regolatori",
        image: imgRegolazione,
        icon: <Book />,
        color: "affari",
        subcategory: false,
        features: [
          {
            text: " Gestione delle tematiche regolatorie, in grado di raccogliere e coordinare le posizioni dei propri associati rispetto alle questioni ed alle proposte introdotte dal Regolatore, consentirà pertanto di rafforzare il ruolo di rappresentanza di Utilitalia nei confronti di alcuni stakeholder istituzionali, rispetto ad un'area tematica trasversale al proprio tessuto associativo. ",
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Antitrust",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'Antitrust",
            to: "/area-affari-regolatori/norme-e-regole/antitrust/list",
          },
          {
            icon: "file",
            title: "UE",
            text: "All'interno della sezione tutte le tematiche regolatorie dell' Unione Europea",
            to: "/area-affari-regolatori/norme-e-regole/ue/list",
          },
          {
            icon: "file",
            title: "Acqua",
            text: "All'interno della sezione tutte le tematiche regolatorie sull'acqua",
            to: "/area-affari-regolatori/norme-e-regole/acqua/list",
          },
          {
            icon: "file",
            title: "Energia",
            text: "All'interno della sezione tutte le tematiche regolatorie inerenti l'energia",
            to: "/area-affari-regolatori/norme-e-regole/energia/list",
          },
          {
            icon: "file",
            title: "Ambiente",
            text: "All'interno della sezione tutte le tematiche regolatorie dell'ambiente",
            to: "/area-affari-regolatori/norme-e-regole/ambiente/list",
          },
        ],
      };
    }
    case "/area-strategie-pianificazione/home":
    case "/area-strategie-pianificazione/home/": {
      return {
        name: "Strategie <span>e</span> Pianificazione <span>di</span> Settore",
        image: imgStrategie,
        icon: <Target />,
        gradient: "strategie",
        color: "strategie",
        /* subcategory: "Norme e Regole", */
        features: [
          {
            title: "DEFINISCE LE STRATEGIE",
            text: `Definisce il Business Plan della Federazione con specifico focus su sostenibilità, policy del comparto energetico, innovazione, PNNR e Sud`,
          },
          {
            title: "REALIZZA STUDI",
            text: `Realizza studi di carattere tecnico, scientifico, economico e finanziario.`,
          },
          {
            title: "INFORMA",
            text: `Informa le imprese associate sulle evoluzioni e le novità normative, nazionali ed internazionali, regolatorie  e tecniche di settore.`,
          },
          {
            title: "SUPPORTA",
            text: `Supporta le Associate durante il percorso verso la transizione ecologica e digitale.`,
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "Tutte le <span>circolari</span>",
            text: "All'interno della sezione tutte le circolari dell'Area",
            to: "/area-strategie-pianificazione/circolari",
          },
          {
            icon: "intersect",
            title: "Studi e <span>ricerche</span>",
            text: "All’interno della sezione gli studi e le ricerche dell’Area con un focus sulla sostenibilità, sulle policy del comparto energetico, sulle politiche di inclusione, sul Business Plan della Federazione, su innovazione e Sud",
            to: "/area-strategie-pianificazione/studi-e-documentazione/list",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "sostenibilita@utilitalia.it",
            tel: "06 94528214",
            color: "var(--strategie)",
            to: "/area-strategie-pianificazione/contatti",
          },
        ],
      };
    }
    case "/servizio-funerario/home":
    case "/servizio-funerario/home/": {
      return {
        name: "Utilitalia - Sefit",
        image: imgSefit,
        icon: <Target />,
        gradient: "funerario",
        color: "funerario",
        title: "Utilitalia - Sefit - Servizio Funerario",
        /* subcategory: "Norme e Regole", */
        features: [
          {
            title: "ASSOCIA GLI OPERATORI",
            text: `SEFIT (servizi funerari italiani) associa le imprese private e a partecipazione pubblica, le gestioni in diretta economia comunale che operano sull’intera gamma dei servizi funerari: gestione di cimiteri, di crematori, illuminazione elettrica votiva, trasporti e onoranze funebri. A livello internazionale rappresenta il settore funerario italiano in ICF (International Cremation Federation), in EFFS (European Federation of Funeral Services) e ha rapporti di collaborazione con ASCE (Association of Significant Cemeteries of Europe).`,
          },
          {
            title: "SUPPORTA GLI ASSOCIATI",
            text: `Offre supporto agli associati seguendo l’evoluzione della normativa di settore a livello nazionale e regionale e rappresentandoli presso le commissioni parlamentari, i ministeri competenti e negli ambiti istituzionali delle regioni. Inoltre, predispone atti normativi da sottoporre alle sedi competenti, realizza studi di carattere tecnico e scientifico su alcuni aspetti di gestione dei cimiteri e dei crematori sia attraverso l’attività dei Gruppi di Lavoro SEFIT (<em>Attività funebre, Cimiteri, Crematori, Valorizzazione culturale e turistica cimiteri</em>), sia con la collaborazione di soggetti esterni.`,
          },
          {
            title: "INFORMA GLI ASSOCIATI",
            text: `Informa gli associati sulle evoluzioni e le novità legislative di settore e sulla giurisprudenza di interesse tramite l’emissione di circolari interpretative; fornisce risposte ai quesiti specifici sottoposti dagli associati; promuove la corretta informazione sull’operatività del settore, la diffusione di <em>best practices</em> e la valorizzazione del patrimonio cimiteriale italiano mediante appositi incontri e documenti.`,
          },
        ],
        boxes: [
          {
            icon: "file",
            title: "<span>SEFIT (www.sefit.org)</span>",
            text: "È il settore funerario di Utilitalia che ricomprende circa 60 soci, le imprese private e a partecipazione pubblica, gestioni in economia comunale che operano sull’intera gamma dei servizi funerari.",
            to: "https://www.sefit.org",
          },
          // {
          //   icon: "file",
          //   title: "Tutte i <span>quesiti</span>",
          //   text: "Tutte le risposte ai quesiti posti dell'Area",
          //   to: "https://www.sefit.org",
          // },
          // {
          //   icon: "intersect",
          //   title: "Studi e <span>ricerche</span>",
          //   text: "All’interno della sezione gli studi e le ricerche dell’Area con un focus sulla sostenibilità, sulle policy del comparto energetico, sulle politiche di inclusione, sul Business Plan della Federazione, su innovazione e Sud",
          //   to: "https://www.sefit.org",
          // },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "segreteria@sefit.org",
            tel: "06 90283171",
            color: "var(--funerario)",
            to: "https://www.sefit.org",
          },
        ],
      };
    }
    case "/servizi-it-multimediali/home":
    case "/servizi-it-multimediali/home/": {
      return {
        name: "Servizi IT e Multimediali",
        title: "Utilitalia - Servizi IT e Multimediali",
        description: "Servizi It e Multimediali si occupa di manutenere l’infrastruttura Tecnologica della Federazione, fornire supporto tecnico sistemistico ai dipendenti di Utilitalia, fornire supporto tecnico in ambito CRM alle aziende per l’accesso e la gestione del portale My Utilitalia, fornire supporto per le riunioni in modalità mista (on line e presenza) presso la Federazione",
       
        image: imgIT,
        icon: <Target />,
        gradient: "comunicazione",
        color: "comunicazione",
        /* subcategory: "Norme e Regole", */
        features: [
          {
            title: "Servizi It e Multimediali",
            text: `Servizi It e Multimediali si occupa di manutenere l’infrastruttura Tecnologica della Federazione, fornire supporto tecnico sistemistico ai dipendenti di Utilitalia, fornire supporto tecnico in ambito CRM alle aziende per l’accesso e la gestione del portale My Utilitalia, fornire supporto per le riunioni in modalità mista (on line e presenza) presso la Federazione`,
          },
        ],
        boxes: [
          {
            icon: "intersect",
            title: "<span>MyUtilitalia</span>",
            text: "Portale per i referenti aziendali",
            to: "https://my.utilitalia.it",
          },
          {
            icon: "envelope",
            title: "I nostri <span>contatti</span>",
            email: "direzionegenerale@utilitalia.it",
            color: "var(--comunicazione)",
            to: "/servizi-it-multimediali/contatti",
          },
        ],
      };
    }
    default:
  }
}
