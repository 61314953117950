import SocialList from "components/social-list/SocialList";

export const menu = [
  {
    title: "Settori",
    voices: [
      {
        title: "Acqua",
        to: "/acqua/home",
      },
      {
        title: "Ambiente",
        to: "/ambiente/home",
      },
      {
        title: "Energia",
        to: "/energia/home",
      },
    ],
  },
  {
    title: "Aree e funzioni",
    voices: [
      {
        title: "Area Lavoro e Relazioni Industriali",
        to: "/area-lavoro-relazioni-industriali/home",
      },
      {
        title: "Area Affari Regolatori",
        to: "/area-affari-regolatori/home",
      },
      {
        title: "Area Giuridico-Legislativa e Fiscale",
        to: "/area-giuridico-legislativa-fiscale/home",
      },
      {
        title: "Area Strategia e Pianificazione",
        to: "/area-strategia-pianificazione/home",
      },
      {
        title: "Affari istituzionali ed europei",
        to: "/affari-istituzionali-ed-europei/home/",
      },
      {
        title: "Amministrazione",
        to: "/amministrazione/home",
      },
      {
        title: "Direzione e organizzazione interna",
        to: "/direzione-generale/organizzazione-e-gestione-interna/home",
      },
      {
        title: "Eventi e Convegni",
        to: "/eventi-convegni/home",
      },
      {
        title: "Media Relations",
        to: "/media-relations/home",
      },
      {
        title: "Servizio Funerario",
        to: "/servizio-funerario/home",
      },
      {
        title: "Servizi IT e Multimediali",
        to: "/servizi-it-multimediali/home/"
    }
      // {
      //   title: "Direzione Generale e Organi ",
      //   to: "/direzione-generale/home",
      // },
      // {
      //   title: "Area Comunicazione",
      //   to: "/area-comunicazione/home",
      // },
    ],
  },
  {
    title: "Utilitalia",
    voices: [
      {
        title: "Presentazione",
        to: "/chi-siamo/utilitalia",
      },
      {
        title: "Statuto",
        to: "/chi-siamo/statuto",
      },
      {
        title: "Struttura",
        to: "/chi-siamo/struttura",
      },
      {
        title: "Organi",
        to: "/chi-siamo/organi",
      },
      {
        title: "Partners",
        to: "/chi-siamo/partner",
      },
      {
        title: "Associate",
        to: "/associate",
      },
      {
        title: "Brochure",
        to: "/chi-siamo/brochure",
      },
      {
        title: "Presentazione",
        to: "/chi-siamo/presentazione",
      },
    ],
  },
  {
    title: "Contatti",
    voices: [
      {
        title: "Circolari",
        to: "/circolari",
      },
      {
        title: "News & Media",
        to: "/news-media/ultime-notizie",
      },
      {
        title: "Contattaci",
        to: "/contatti",
      },
    ],
  },
  {
    title: "Legal",
    voices: [
      {
        title: "Privacy Policy",
        to: "/legal/privacy-policy",
      },
      {
        title: "Diritti esercitabili dall’interessato ",
        path: "https://cms.utilitalia.it/File/informativa_diritti_esercitabili_interessato.pdf",
      },
      {
        title: "Note legali",
        to: "/legal/note-legali",
      },

      {
        title: "Social Media Policy",
        to: "/legal/comunicazioni",
      },
      {
        title: "Informativa Soggetti in Rapporto con l'Ente",
        path: "https://www.utilitalia.it/files/informativa-soggetti-in-rapporto-con-utilitalia.pdf",
      },
      {
        title: "Informativa Fornitori",
        path: "https://www.utilitalia.it/files/informativa-fornitori.pdf",
      },
    ],
  },
  {
    title: "Social",
    icon: <SocialList />,
  },
];
