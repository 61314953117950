import React, { Component } from "react";
import "./HamburgerMenu.scss";
import axios from "axios";
import SocialList from "components/social-list/SocialList";
import { Link } from "react-router-dom";
import { Drop, Leaf, Lightning, ArrowLeft, ArrowRight } from "phosphor-react";

const settori = [
  {
    name: "Acqua",
    to: "/acqua/home",
    icon: (
      <Drop weight="bold">
        <linearGradient id="acqua" gradientTransform="rotate(135)">
          <stop offset="0%" stopColor="#0066a8" />
          <stop offset="50%" stopColor="#0a85bf" />
          <stop offset="100%" stopColor="#279fca" />
        </linearGradient>
      </Drop>
    ),
  },
  {
    name: "Ambiente",
    to: "/ambiente/home",
    icon: (
      <Leaf weight="bold">
        <linearGradient id="ambiente" gradientTransform="rotate(135)">
          <stop offset="0%" stopColor="#398d99" />
          <stop offset="50%" stopColor="#36b29b" />
          <stop offset="100%" stopColor="#06c96d" />
        </linearGradient>
      </Leaf>
    ),
  },
  {
    name: "Energia",
    to: "/energia/home",
    icon: (
      <Lightning weight="bold">
        <linearGradient id="energia" gradientTransform="rotate(135)">
          <stop offset="0%" stopColor="#ff8801" />
          <stop offset="50%" stopColor="#ffbd0d" />
          <stop offset="100%" stopColor="#eb650c" />
        </linearGradient>
      </Lightning>
    ),
  },
];

const menu = [
  {
    title: "Aree e Funzioni",
    submenu: true,
    voices: [
      {
        title: "Area affari regolatori",
        to: "/area-affari-regolatori/home",
      },
      {
        title: "Area lavoro e relazioni industriali",
        to: "/area-lavoro-relazioni-industriali/home",
      },
      {
        title: "Area giuridico legislativa fiscale",
        to: "/area-giuridico-legislativa-fiscale/home",
      },
      // {
      //   title: "Area comunicazione",
      //   to: "/area-comunicazione/home",
      // },
      {
        title: "Area Strategie e Pianificazione",
        to: "/area-strategie-pianificazione/home",
      },
      {
        title: "Affari istituzionali ed europei",
        to: "/affari-istituzionali-ed-europei/home/",
      },
      {
        title: "Amministrazione",
        to: "/amministrazione/home",
      },
      {
        title: "Direzione e organizzazione interna",
        to: "/direzione-generale/organizzazione-e-gestione-interna/home",
      },
      {
        title: "Eventi e Convegni",
        to: "/eventi-convegni/home",
      },
      {
        title: "Media Relations",
        to: "/media-relations/home",
      },
      {
        title: "Servizio Funerario",
        to: "/servizio-funerario/home",
      },
      {
        title: "Servizi IT e Multimediali",
        to: "/servizi-it-multimediali/home/"
    }
      // {
      //   title: "Direzione generale",
      //   to: "/direzione-generale/home",
      // },
    ],
  },
  {
    title: "Chi siamo",
    submenu: true,
    voices: [
      {
        title: "Utilitalia",
        to: "/chi-siamo/utilitalia",
      },
      {
        title: "Statuto",
        to: "/chi-siamo/statuto",
      },
      {
        title: "Codice Etico",
        to: "/chi-siamo/codice-etico",
      },
      {
        title: "Struttura",
        to: "/chi-siamo/struttura",
      },
      {
        title: "Organi",
        to: "/chi-siamo/organi",
      },
      {
        title: "Partner",
        to: "/chi-siamo/partner",
      },
      {
        title: "Brochure",
        to: "/chi-siamo/brochure",
      },
      {
        title: "Presentazione",
        to: "/chi-siamo/presentazione",
      },
    ],
  },
  {
    title: "Circolari",
    submenu: false,
    to: "/circolari",
  },
  {
    title: "Associate",
    submenu: false,
    to: "/associate",
  },
  {
    title: "News & Media",
    submenu: true,
    voices: [
      {
        title: "Le ultime notizie",
        to: "/news-media/ultime-notizie",
      },
      // {
      //   title: "Rassegna Stampa",
      //   to: "/news-media/rassegna-stampa",
      // },
      {
        title: "Press Kit",
        to: "/news-media/press-kit",
      },
    ],
  },
  {
    title: "Eventi",
    submenu: true,
    voices: [
      {
        title: "Calendario Eventi",
        to: "/calendario-eventi",
      },
      {
        title: "Archivio Eventi",
        to: "/archivio-eventi",
      },
    ],
  },
  {
    title: "Atti e Pubblicazioni",
    submenu: true,
    voices: [
      {
        title: "Atti",
        to: "/atti",
      },
      {
        title: "Pubblicazioni",
        to: "/pubblicazioni/list",
      },
    ],
  },
  {
    title: "Contatti",
    submenu: false,
    to: "/contatti",
  },
];

export default class HamburgerMenu extends Component {
  state = {
    subMenu: false,
    active: null,
    voices: [],
    sessionId: sessionStorage.getItem("sessionId"),
    uniqueCode: sessionStorage.getItem("uniqueCode"),
    fullName: sessionStorage.getItem("fullName"),
    alertMessage: sessionStorage.getItem("alertMessage"),
  };

  subMenu = (element) => {
    this.setState({
      subMenu: !this.state.subMenu,
      active: element.title,
      voices: element.voices,
    });
  };

  handleClose = () => {
    this.props.toggleVisibility();
    this.setState({ subMenu: false });
  };

  _logout = async () => {
    const headers = {
      headers: { "Session-Id": this.state.sessionId },
    };
    try {
      await axios.post(
        "https://api.utilitalia.it/CMS/Session/Logout",
        {},
        headers
      );
      sessionStorage.removeItem("sessionId");
      sessionStorage.removeItem("uniqueCode");
      sessionStorage.removeItem("fullName");
      sessionStorage.removeItem("alertMessage");
      document.cookie =
        "sessionId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.reload();
    } catch (err) {
      console.log("Error logging out", err);
      this.setState({
        loginError: true,
      });
    }
  };

  render() {

    return (
      <div id="hamburger-menu" className={this.props.visibility}>
        <div className={"sub-menu " + (this.state.subMenu && "show")}>
          <div className="sub-menu-top">
            <ArrowLeft size={24} onClick={this.subMenu} />
            <span>{this.state.active}</span>
            <div></div>
          </div>
          <div className="bottom">
            {this.state.voices &&
              this.state.voices.map((element, i) => (
                <Link
                  onClick={this.handleClose}
                  className="voice"
                  to={element.to}
                  key={i}
                >
                  {element.title}
                </Link>
              ))}
          </div>
        </div>
        <div className="heading">
          <span>MENU</span>
          <div className="center">
            {!this.state.sessionId ? (
              <Link to="/login" className="btn big">
                Accedi
              </Link>
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this._logout()}
                className="btn big"
              >
                Esci
              </div>
            )}
          </div>
          <div></div>
        </div>
        {this.state.sessionId && <ul className="user-menu-ham">
            <li><Link to="/cambia-password">Cambio password</Link></li>
            <li style={{margin:'10px 0'}}>Il tuo codice univoco è <strong dangerouslySetInnerHTML={{__html:this.state.uniqueCode}}/></li>
            { typeof this.state.alertMessage !== 'undefined' && this.state.alertMessage !== 'null'  && this.state.alertMessage !== null && this.state.alertMessage !== '' && <li dangerouslySetInnerHTML={{__html:this.state.alertMessage}} /> }
        </ul> }
        <div className="top">
          {settori.map((element, i) => (
            <Link to={element.to} onClick={this.handleClose} key={i}>
              {element.icon}
              <span>{element.name}</span>
            </Link>
          ))}
        </div>
        <div className="line"></div>
        <div className="sections-menu">
          <div className="top-sections-menu">
            {menu.map((element, i) => (
              <div className="voice" key={i}>
                {element.submenu ? (
                  <div onClick={() => this.subMenu(element)}>
                    <span>{element.title}</span> <ArrowRight size={24} />
                  </div>
                ) : (
                  <Link onClick={this.handleClose} to={element.to}>
                    {element.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
          <div className="bottom-sections-menu">
            <SocialList />
          </div>
        </div>
      </div>
    );
  }
}
